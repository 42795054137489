import useTypedSelector from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import React, {useCallback, useEffect} from 'react';
import {View} from 'react-native';

import {navigationRef} from 'src/Nav/navigationHelpers';
import {
  setCurrentChallengeId,
  setCurrentLocationId,
} from 'src/Redux/reducers/game_v2.reducer';

import {dispatchAction} from 'src/Utils/helpers';

import {useLocationId} from 'src/Hooks/gameHooks';

import {TNavigationRouteParams} from 'src/types/TNavigationRouteParams';
import {logAttributesForCrashlytics} from 'src/Utils/fireUtils';

// literally only for deciding what page to be on
// everything else done by MainGameDataUpdater
const MainGameScreenController: React.FC = () => {
  const classic_hunt = useTypedSelector(
    (state) => state?.group?.info?.classic_hunt,
  );
  const huntStarted = useTypedSelector(
    (state) => state?.group?.info?.huntStarted,
  );
  const huntPaused = useTypedSelector(
    (state) => state?.group?.info?.huntPaused,
  );
  const huntFinished = useTypedSelector(
    (state) => state?.group?.info?.huntFinished,
  );
  const groupPhoto = useTypedSelector(
    (state) => state?.group?.info?.groupPhoto,
  );
  const huntIntroDone = useTypedSelector(
    (state) => state?.group?.info?.huntIntroDone,
  );

  const isClassicHunt = !!classic_hunt;

  const locationId = useLocationId();

  const userId = useTypedSelector((state) => state.user?.userId);
  const userGroupId = useTypedSelector(
    (state) => state.user?.info?.currentHuntInfo?.groupId,
  );

  console.log('MainGameScreenController tab rerender', {userGroupId});

  const currentScreen = useTypedSelector(
    (state) => state.current_screen?.currentScreen,
  );

  const hasHostControl = useTypedSelector(
    (state) => state.event?.info?.hasHostControl,
  );

  const currentLocationId = useTypedSelector(
    (state) => state.event?.info?.currentLocationId || '',
  );

  const isCurrentScreenHuntPaused = useTypedSelector(
    (state) => state.current_screen?.currentScreen == 'HuntPaused',
  );
  const isCurrentScreenAudioTourMain = useTypedSelector(
    (state) => state.current_screen?.currentScreen == 'AudioTourMain',
  );

  const isCurrentScreenHuntLoading = useTypedSelector(
    (state) => state.current_screen?.currentScreen == 'HuntLoading',
  );

  const isCurrentScreenHuntFinished = useTypedSelector(
    (state) => state.current_screen?.currentScreen == 'HuntFinished',
  );

  const isCurrentScreenHuntReviewScreen = useTypedSelector(
    (state) => state.current_screen?.currentScreen == 'HuntReviewScreen',
  );

  const isCurrentScreenHuntSocialBonuses = useTypedSelector(
    (state) => state.current_screen?.currentScreen == 'HuntSocialBonuses',
  );

  const isCurrentScreenGameLobby = useTypedSelector(
    (state) => state.current_screen?.currentScreen == 'ScavengerHuntGameLobby',
  );

  const isAudioTour = useTypedSelector(
    (state) => state.group?.info?.huntType == 'audioTour',
  );

  const isClassicScreen = useTypedSelector(
    (state) => state.current_screen?.currentScreen == 'ClassicChallenges',
  );

  const isCurrentScreenCharacterSelection = useTypedSelector(
    (state) =>
      state.current_screen?.currentScreen == 'ScavengerHuntCharacterSelection',
  );

  const isCurrentScreenHuntIntro = useTypedSelector(
    (state) => state.current_screen?.currentScreen == 'HuntIntro',
  );

  const isCurrentScreenTeamPhoto = useTypedSelector(
    (state) => state.current_screen?.currentScreen == 'TeamPhoto',
  );

  const isCurrentScreenHybridCharacterList = useTypedSelector(
    (state) => state.current_screen?.currentScreen == 'HybridCharacterList',
  );

  const isCurrentScreenHybridCharacterReveal = useTypedSelector(
    (state) => state.current_screen?.currentScreen == 'HybridCharacterReveal',
  );

  const hasReviewedHybridCharacter = useTypedSelector((state) =>
    userId
      ? state.group?.info?.players?.[userId]?.hasReviewedHybridCharacter
      : false,
  );

  // there is a button that allows them to click I have reviewed my character
  const hasSeenHybridCharacterList = useTypedSelector((state) =>
    userId
      ? state.group?.info?.players?.[userId]?.hasSeenHybridCharacterList
      : false,
  );

  const isHybridHunt = useTypedSelector((state) =>
    [
      'coworker_feud',
      'virtual_coworker_feud',
      'escape_room',
      'murder_mystery',
      'virtual_office_games',
    ].includes(state.group?.info?.huntType || ''),
  );

  const enablePlayerChallenges = useTypedSelector(
    (state) => state.event?.info?.enablePlayerChallenges ?? true,
  );

  const huntType = useTypedSelector(
    (state) => state.group?.info?.huntType || '',
  );

  const showAnswers = useTypedSelector(
    (state) => state.event?.info?.showAnswers || false,
  );

  const isModal = useTypedSelector((state) => {
    const currentScreen = state.current_screen?.currentScreen;
    const isModal =
      [
        'CESModal',
        'ConfirmModal',
        'FAQModal',
        'SupportModal',
        'FoxShop',
        'PhotoVideoCamera',
        'PlayerDashboard_v2',
        'EventDashboardWrapper',
        'QuestionFeedBackModal',
        'HuntPhotos',
        'EventDashboard',
        'LandingPage',
        'HuntFinishedPlayer',
        'ChangeHuntModal',
      ]?.includes?.(currentScreen) || currentScreen.includes('Modal');
    console.log('checking if modal', {isModal, currentScreen});
    return isModal;
  });

  const userReview = useTypedSelector((state) => {
    const players = state?.group?.info?.players || {};
    console.log('userReview useSelector', {userId, players});
    const userReview =
      userId && players && players?.[userId]
        ? players?.[userId]?.reviews
        : null;
    console.log({userReview});
    return userReview;
  });

  const correctLocationId = useTypedSelector((state) => {
    const locations = state?.game_v2?.locations;
    const locationList = state?.game_v2?.locationList;

    if (!locations || !locationList) {
      return null;
    }

    if (
      !!hasHostControl &&
      !!isHybridHunt &&
      (currentLocationId === 'Leaderboard' ||
        currentLocationId === 'StoryRound' ||
        currentLocationId === 'LocationsFinished')
    ) {
      return currentLocationId;
    }

    if (!!hasHostControl && !!isHybridHunt) {
      return locationList.filter((key) => key?.includes(currentLocationId))[0];
    }

    const unFinishedLocationIds = locationList.filter((key) => {
      const location = locations[key];

      // fixing for barhunt location closed

      // if (location?.startTime) {
      //   hasStartedLocationChallenges = true;
      // }
      return !location?.locationChallengesCompleteTime;
    });
    console.log({unFinishedLocationIds});
    return unFinishedLocationIds?.[0] || 'LocationsFinished';
  });

  const correctLocation = useTypedSelector((state) => {
    return correctLocationId
      ? state?.game_v2?.locations?.[correctLocationId]
      : null;
  });

  const correctChallenge = useTypedSelector((state) => {
    const allChallenges = state?.game_v2?.allChallenges;
    if (!correctLocation || !allChallenges) {
      return null;
    }
    const challengeList = correctLocation?.challengeList;
    // console.log({challenges});

    // the navigation challenge was complete, so now finding the index of the highest non-competed challenge
    const highestNonCompletedChallengeId = challengeList?.filter((key) => {
      return (
        !allChallenges[key]?.scoreReviewTime &&
        !allChallenges[key]?.completeTime
      );
    })?.[0];

    console.log({highestNonCompletedChallengeId, challengeList});

    const newCorrectChallenge = highestNonCompletedChallengeId
      ? allChallenges[highestNonCompletedChallengeId]
      : null;

    if (!newCorrectChallenge) {
      return 'LocationFinished';
    }

    return newCorrectChallenge;
  });

  console.log({correctLocationId, correctChallenge});

  const isHuntLoading = useTypedSelector((state) => {
    // no group info
    if (!state.group?.info) {
      return true;
      // if hunt started also check for locations & questions
    } else if (state?.group?.info?.huntStarted) {
      if (
        !state?.game_v2?.locationList?.[0] &&
        !state?.game_v2?.classicChallengeList?.[0]
      ) {
        return true;
      }
    }
    return false;
  });

  const devicePlayersHaveCharacters = useTypedSelector((state) => {
    const players = state.group?.info?.players;
    if (!players) {
      return 0;
    }
    const allPlayers = Object.values(players);
    const devicePlayersHaveCharacters =
      allPlayers &&
      allPlayers
        .filter((player) => userId == player.parentUserId)
        .every((player) => player.character);
    return devicePlayersHaveCharacters;
  });

  useEffect(() => {
    logAttributesForCrashlytics('huntType', huntType);
    logAttributesForCrashlytics(
      'isClassicHunt',
      isClassicHunt ? 'true' : 'false',
    );
    logAttributesForCrashlytics(
      'isHybridHunt',
      isHybridHunt ? 'true' : 'false',
    );
    logAttributesForCrashlytics('isAudioTour', isAudioTour ? 'true' : 'false');
    logAttributesForCrashlytics(
      'hasHostControl',
      hasHostControl ? 'true' : 'false',
    );
    logAttributesForCrashlytics('huntStarted', huntStarted ? 'true' : 'false');
    logAttributesForCrashlytics(
      'huntFinished',
      huntFinished ? 'true' : 'false',
    );
    logAttributesForCrashlytics(
      'huntIntroDone',
      huntIntroDone ? 'true' : 'false',
    );
    logAttributesForCrashlytics('huntPaused', huntPaused ? 'true' : 'false');
    logAttributesForCrashlytics('groupPhoto', groupPhoto || undefined);
    logAttributesForCrashlytics(
      'isHuntLoading',
      isHuntLoading ? 'true' : 'false',
    );
  }, [
    huntType,
    isClassicHunt,
    isHybridHunt,
    isAudioTour,
    hasHostControl,
    huntStarted,
    huntIntroDone,
    huntFinished,
    huntPaused,
    groupPhoto,
    isHuntLoading,
  ]);

  // key function
  // handling next
  const handleNext = useCallback(
    (force = false) => {
      console.log('MainGameScreenController: handling redirect', {
        huntPaused,
        huntStarted,
        huntFinished,
        groupPhoto,
        huntIntroDone,
        devicePlayersHaveCharacters,
        isCurrentScreenTeamPhoto,
        correctLocationId,
        correctChallenge,
        isHuntLoading,
        isCurrentScreenHybridCharacterList,
        isCurrentScreenHybridCharacterReveal,
        hasReviewedHybridCharacter,
        hasSeenHybridCharacterList,
        // huntPostCardFinished,
      });

      // checking if all hunt data is loaded
      if (isHuntLoading) {
        return console.log('Hunt still loading');
      }

      // simple function to see if on right page
      const checkIfRightPageOrNavigate = (
        condition: boolean,
        page: keyof TNavigationRouteParams,
        source: string = 'MainGameScreenController checkIfRightPageOrNavigate',
      ) => {
        if (condition) {
          return console.log('checkingCurrentPageErrors on right page:', {
            condition,
            page,
          });
        } else {
          console.log('checkingCurrentPageErrors on right page navigating:', {
            condition,
            page,
          });
          return navigationRef.navigate(page, {}, source);
        }
      };

      if (huntPaused) {
        return checkIfRightPageOrNavigate(
          isCurrentScreenHuntPaused,
          'HuntPaused',
          'MainGameScreenController: HuntPaused huntPaused',
        );
      }

      // checking if a modal is open
      if (
        (!isHybridHunt && isModal && !force) ||
        (isHybridHunt &&
          isModal &&
          !force &&
          !currentScreen.includes('EventDashboard') &&
          !currentScreen.includes('EventDashboard') &&
          currentLocationId !== 'Leaderboard')
      ) {
        return console.log('CESModal blocked redirect', currentScreen);
      }

      console.log('checkingCurrentPageErrors fired');

      // checking if hunt finished, if finished either hunt post card
      // or hunt finished
      if (huntFinished) {
        console.log({userReview});
        if (!userReview) {
          return checkIfRightPageOrNavigate(
            isCurrentScreenHuntReviewScreen,
            'HuntReviewScreen',
            'MainGameScreenController: HuntReviewScreen !userReview',
          );
        } else if (
          !!userReview?.r_app &&
          userReview?.r_app > 4 &&
          !userReview?.huntSocialBonusDone
        ) {
          return checkIfRightPageOrNavigate(
            isCurrentScreenHuntSocialBonuses,
            'HuntSocialBonuses',
            'MainGameScreenController: HuntSocialBonuses userReview?.r_app > 4 && !userReview?.huntSocialBonusDone',
          );
        } else {
          return checkIfRightPageOrNavigate(
            isCurrentScreenHuntFinished,
            'HuntFinished',
            'MainGameScreenController: HuntFinished huntFinished',
          );
        }

        // }
      }

      // hunt is not started, so must be in game lobby
      if (!huntStarted) {
        return checkIfRightPageOrNavigate(
          isCurrentScreenGameLobby,
          'ScavengerHuntGameLobby',
          'MainGameScreenController: ScavengerHuntGameLobby !huntStarted',
        );
      }

      // checking if all players have info
      if (
        !devicePlayersHaveCharacters &&
        !isHybridHunt &&
        enablePlayerChallenges
      ) {
        return checkIfRightPageOrNavigate(
          isCurrentScreenCharacterSelection,
          'ScavengerHuntCharacterSelection',
          'MainGameScreenController: ScavengerHuntCharacterSelection !devicePlayersHaveCharacters',
        );
      }

      // hybrid hunt character screen logic
      if (huntType === 'murder_mystery') {
        if (!hasSeenHybridCharacterList) {
          return checkIfRightPageOrNavigate(
            isCurrentScreenHybridCharacterList,
            'HybridCharacterList',
            'MainGameScreenController: HybridCharacterList !hasSeenHybridCharacterList',
          );
        } else if (!hasReviewedHybridCharacter) {
          return checkIfRightPageOrNavigate(
            isCurrentScreenHybridCharacterReveal,
            'HybridCharacterReveal',
            'MainGameScreenController: HybridCharacterReveal !hasReviewedHybridCharacter',
          );
        }
      }

      if (isAudioTour) {
        return checkIfRightPageOrNavigate(
          isCurrentScreenAudioTourMain,
          'AudioTourMain',
          'MainGameScreenController: AudioTourMain isAudioTour',
        );
      }

      // checking if hunt intro is done
      if (!huntIntroDone) {
        return checkIfRightPageOrNavigate(
          isCurrentScreenHuntIntro,
          'HuntIntro',
          'MainGameScreenController: HuntIntro !huntIntroDone',
        );
      }

      // checking if group photo is taken
      if (!groupPhoto) {
        return checkIfRightPageOrNavigate(
          isCurrentScreenTeamPhoto,
          'TeamPhoto',
          'MainGameScreenController: TeamPhoto !groupPhoto',
        );
      }

      // remaining logic depends if classic or not
      if (isClassicHunt) {
        return checkIfRightPageOrNavigate(
          isClassicScreen,
          'ClassicChallenges',
          'MainGameScreenController: ClassicChallenges isClassicHunt',
        );

        //
      } else {
        // setting the location challenges
        if (correctLocationId != locationId || isCurrentScreenHuntLoading) {
          dispatchAction(
            setCurrentLocationId(
              correctLocationId == 'LocationsFinished'
                ? null
                : correctLocationId,
            ),
          );
        }

        console.log('------------------------------------');
        console.log('--Determining Right Location Page---');
        console.log('------------------------------------');

        console.log({correctLocation});

        // navigating to hunt outro confirmation page
        if (correctLocationId == 'Leaderboard') {
          return navigationRef.navigate(
            'EventDashboard',
            {
              currentView: 'Leaderboard',
              hideBackButton: true,
            },
            'MainGameScreenController: Leaderboard',
          );
        } else if (correctLocationId == 'StoryRound') {
          return checkIfRightPageOrNavigate(
            currentScreen == 'StoryRound',
            'StoryRound',
            `MainGameScreenController: StoryRound'`,
          );
        } else if (correctLocationId == 'LocationsFinished') {
          dispatchAction(setCurrentLocationId(null));
          dispatchAction(setCurrentChallengeId(null));
          return checkIfRightPageOrNavigate(
            currentScreen == 'HuntOutro',
            'HuntOutro',
            `MainGameScreenController: HuntOutro correctLocationId == 'LocationsFinished'`,
          );
          // navigating to hunt outro confirmation page
        } else if (!correctLocation?.completeTime) {
          dispatchAction(setCurrentChallengeId(null));
          return checkIfRightPageOrNavigate(
            currentScreen == 'NavigationChallenge',
            'NavigationChallenge',
            'MainGameScreenController: MainGameScreenController !correctLocation?.completeTime',
          );

          // navigating to hunt outro confirmation page
        } else if (correctChallenge == 'LocationFinished') {
          if (!hasHostControl) {
            return checkIfRightPageOrNavigate(
              currentScreen == 'LocationComplete',
              'LocationComplete',
              `MainGameScreenController: LocationComplete correctChallenge == 'LocationFinished'`,
            );
          }

          // navigating to hunt outro confirmation page
        } else if (correctChallenge) {
          // dispatchAction(setCurrentChallengeId(correctChallenge?.challengeId));
          if (
            !isHybridHunt ||
            (!!isHybridHunt &&
              currentScreen !== 'ChallengeAttemptAndResultModal')
          ) {
            return checkIfRightPageOrNavigate(
              currentScreen == 'LocationChallenges',
              'LocationChallenges',
              `MainGameScreenController: LocationChallenges correctChallenge`,
            );
          }
        }
      }

      return console.warn('Made to end!!');
    },
    [
      currentScreen,
      huntPaused,
      devicePlayersHaveCharacters,
      huntIntroDone,
      isCurrentScreenTeamPhoto,
      isModal,
      huntFinished,
      isCurrentScreenHuntLoading,
      correctChallenge,
      isCurrentScreenHuntFinished,
      correctLocationId,
      currentLocationId,
      isHuntLoading,
      isCurrentScreenAudioTourMain,
      userReview,
      isCurrentScreenHuntSocialBonuses,
      groupPhoto,
      huntStarted,
      JSON.stringify(correctChallenge),
      isAudioTour,
      isClassicHunt,
      isCurrentScreenGameLobby,
      isCurrentScreenCharacterSelection,
      isCurrentScreenHuntIntro,
      correctLocation,
      locationId,
      navigationRef,
      isCurrentScreenHybridCharacterList,
      isCurrentScreenHybridCharacterReveal,
      hasReviewedHybridCharacter,
      hasSeenHybridCharacterList,
    ],
  );

  useEffect(() => {
    handleNext();
  }, [
    currentScreen,
    huntPaused,
    devicePlayersHaveCharacters,
    huntIntroDone,
    isCurrentScreenTeamPhoto,
    isModal,
    huntFinished,
    isCurrentScreenHuntLoading,
    correctChallenge,
    isCurrentScreenHuntFinished,
    correctLocationId,
    currentLocationId,
    isHuntLoading,
    isCurrentScreenAudioTourMain,
    userReview,
    isCurrentScreenHuntSocialBonuses,
    groupPhoto,
    huntStarted,
    JSON.stringify(correctChallenge),
    isAudioTour,
    isClassicHunt,
    isCurrentScreenGameLobby,
    isCurrentScreenCharacterSelection,
    isCurrentScreenHuntIntro,
    correctLocation,
    locationId,
    navigationRef,
    isCurrentScreenHybridCharacterList,
    isCurrentScreenHybridCharacterReveal,
    hasReviewedHybridCharacter,
    hasSeenHybridCharacterList,
  ]);

  constants.handleNext = handleNext;

  console.log('MainHuntController Decided to render');

  return <View></View>;
};

export default MainGameScreenController;

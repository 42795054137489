import useTypedSelector from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import React, {useState, useEffect, useRef} from 'react';
import {TouchableOpacity, View, Text, Animated} from 'react-native';
import {ScrollView} from 'react-native-gesture-handler';
import {SafeAreaView} from 'react-native-safe-area-context';

import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';

////////////// utils
import {formatNumberDisplayAmount} from 'src/Utils/formatHelper';
import {markClassicLocationAllComplete} from 'src/Utils/huntFunctions_classic';

/////////////// components
import {navigationRef} from 'src/Nav/navigationHelpers';
import {
  BodyText,
  ClassicChallengeCard,
  ClassicIconButton,
  CloseIconButton,
  HtmlBlock,
} from 'src/Modules/CommonView';
import {useLocation} from 'src/Hooks/gameHooks';

import CustomActivityIndicator from 'src/Modules/CommonView/CustomActivityIndicator';
import {classicPhotoVideoCompletionEvent} from 'src/Utils/huntFunctions_classic';

import {TChallenge} from 'src/types/TChallenge';
import ExpoImage from 'src/Utils/ExpoImage';
import {cloudFlareOptimizeImage} from 'src/Utils/helpers';
import {updateDataAtPath} from 'src/Utils/fireUtils';
import {setChallengeStartTime} from 'src/Utils/huntFunctions_v2';

const LocationChallengeListModal = () => {
  const {width, height} = useWindowDimensionsSafe();

  const locations = useTypedSelector((state) => state.game_v2);
  const groupId = useTypedSelector((state) => state.group.info?.groupId);
  const allChallenges = useTypedSelector(
    (state) => state.game_v2.allChallenges,
  );

  const location = useLocation();

  const postContent = location?.postContent || '';

  const challengeLocation =
    allChallenges && location && location.locationId !== null
      ? allChallenges[location.locationId]
      : undefined;

  const [showChallenges, setShowChallenges] = useState(
    challengeLocation?.completeTime ? false : true,
  );

  const animation = useRef(new Animated.Value(showChallenges ? 1 : 0)).current;

  const locationCheckInTime = location?.completeTime;

  const [timeDifference, setTimeDifference] = useState(0);

  useEffect(() => {
    if (challengeLocation?.completeTime) {
      setShowChallenges(false);
    } else {
      setShowChallenges(true);
    }
  }, [challengeLocation?.completeTime]);

  useEffect(() => {
    Animated.timing(animation, {
      toValue: showChallenges ? 1 : 0,
      duration: 300,
      useNativeDriver: false,
    }).start();
  }, [showChallenges]);

  useEffect(() => {
    // Function to update time difference every second
    const updateTimer = () => {
      if (locationCheckInTime != null) {
        const currentTime = Date.now();
        setTimeDifference(
          Math.floor((currentTime - locationCheckInTime) / 1000),
        );
      }
    };

    // Initial call to update the timer
    updateTimer();

    // Set up an interval to update the time every second
    const interval = setInterval(updateTimer, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, [locationCheckInTime]); // locationCheckInTime is static, only initial use matters

  const toggleChallenges = () => {
    Animated.timing(animation, {
      toValue: showChallenges ? 0 : 1, // Toggle between 0 (hidden) and 1 (shown)
      duration: 300, // Duration of the animation in milliseconds
      useNativeDriver: false, // Animating layout properties, so useNativeDriver should be false
    }).start(() => setShowChallenges((prev) => !prev)); // Update the state after the animation finishes
  };

  const animatedHeight = animation.interpolate({
    inputRange: [0, 1],
    outputRange: [
      0,
      challengeLocation?.completeTime ? height - 600 : height - 450,
    ], // Adjust these values based on your needs
  });

  // Calculate hours, minutes, and seconds
  const hours = Math.floor(timeDifference / 3600);
  const minutes = Math.floor((timeDifference % 3600) / 60);
  const seconds = timeDifference % 60;

  // Construct the time string
  const checkInTimeText = `You checked in ⏱️ ${
    hours > 0 ? `${hours} hr ` : ''
  }${minutes} min ${seconds} secs ago`;

  if (!location) {
    console.error('LocationChallengeListModal.tsx: location not found');
    return <></>;
  }

  const {locationId, name, challengeList} = location;

  const checkMarkAllLocationChallengesComplete = (challenge: TChallenge) => {
    const {locationId} = location;
    const allChallengesComplete = challengeList?.every((key) => {
      if (!allChallenges) {
        console.error(
          'LocationChallengeListModal.tsx: allChallenges not found',
        );
        return false;
      }
      const locationChallenges = allChallenges[key];
      return !!locationChallenges?.completeTime || key == challenge.challengeId;
    });

    if (allChallengesComplete) {
      !!locationId && markClassicLocationAllComplete(locationId);
    }
  };

  const handlePhotoComplete = async ({
    photoUrl,
    shareUUID,
    challenge,
  }: {
    photoUrl: string;
    shareUUID: string;
    challenge: TChallenge;
  }) => {
    const {challengeId, question} = challenge;

    challengeId &&
      classicPhotoVideoCompletionEvent({
        photoUrl: photoUrl,
        challengeId,
        shareUUID,
        description: question || 'Challenge',
      });
  };

  const handleVideoComplete = ({
    photoUrl,
    thumbnail,
    shareUUID,
    challenge,
  }: {
    photoUrl: string;
    thumbnail?: string;
    shareUUID: string;
    challenge: TChallenge;
  }) => {
    const {challengeId, question} = challenge;

    challengeId &&
      classicPhotoVideoCompletionEvent({
        photoUrl: photoUrl,
        thumbnail: thumbnail,
        challengeId,
        shareUUID,
        description: question || 'Challenge',
      });
  };

  const handleSelectChallenge = (challenge: TChallenge) => {
    const {
      type,
      challengeId,
      isPlayerChallenge = false,
      lightningTimerSeconds = 0,
    } = challenge;

    let photoGradePath = `scavengerhunt/completions/${groupId}/location_v2/allChallenges/${challengeId}`;
    if (!!challengeId.startsWith('cp_')) {
      photoGradePath = `scavengerhunt/completions/${groupId}/player/${challengeId}`;
    }

    if (type === 'photo' || type === 'video') {
      return navigationRef.navigate(
        'PhotoVideoCamera',
        {
          onPhotoFirstUploadedToFirebase: (firebasePhoto) => {
            updateDataAtPath(photoGradePath, {
              preliminaryFirebasePhoto: firebasePhoto,
              preliminaryFirebasePhotoAttemptTime: Date.now(),
              isGradingInProgress: true,
            });
          },
          onCapture: (data) => {
            if (type === 'photo') {
              handlePhotoComplete({
                photoUrl: data?.downloadURL,
                shareUUID: data?.shareUUID,
                challenge,
              });
              checkMarkAllLocationChallengesComplete(challenge);
            } else if (type === 'video') {
              handleVideoComplete({
                thumbnail: data?.thumbnail,
                photoUrl: data?.downloadURL,
                shareUUID: data?.shareUUID,
                challenge,
              });

              checkMarkAllLocationChallengesComplete(challenge);
            }
          },
          onReport: () => {
            navigationRef.navigate(
              'ReportModal',
              null,
              'ScavengerHunt_v2/Classic/LocationChallengeListModal.tsx',
            );
          },
          aiScoring: type == 'photo',
          text: challenge.question || 'Take a photo',
          shareUUID: challenge.shareUUID,
          photoUrl: challenge.photoUrl,
          isVideo: type == 'video',
          challengeId: challenge.challengeId,
        },
        'LocationChallengeListModal',
      );
    }

    const isLightningTimerPlayerChallenge =
      isPlayerChallenge && lightningTimerSeconds;

    if (isLightningTimerPlayerChallenge && !challenge.startTime) {
      setChallengeStartTime(challengeId);
    }

    // result and attempt modal
    return navigationRef.navigate(
      'ChallengeAttemptAndResultModal',
      {
        challengeId: challenge.challengeId,
      },
      'LocationChallengeListModal',
    );
  };

  if (!locations || !allChallenges) {
    return (
      <View
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: constants?.color?.white,
        }}>
        <CustomActivityIndicator />
      </View>
    );
  }

  let totalLocationPoints = 0;
  (challengeList || [])?.map?.((key) => {
    const challenge = allChallenges[key];
    const score = challenge?.points || 100;

    let possiblePhotoBonusPoints = 0;
    if (challenge.type === 'photo' && challenge?.bonusScoreData) {
      possiblePhotoBonusPoints = 200;
    }

    totalLocationPoints =
      score + totalLocationPoints + possiblePhotoBonusPoints;
  }, 0);

  let completedChallenges = 0;
  let earnedLocationPoints = 0;
  let totalLocationBonusPoints = 0;
  (challengeList || [])?.map?.((key) => {
    const challenge = allChallenges[key];
    const score = challenge.groupPointsEarned || 0;
    if (challenge?.completeTime) {
      completedChallenges++;
    }

    const bonusScore = challenge?.bonusScoreData;
    const bonusPoints =
      (bonusScore?.accuracy_score ?? 0) +
      (bonusScore?.fun_of_photo_creativity ?? 0);
    totalLocationBonusPoints += bonusPoints;

    earnedLocationPoints = score + earnedLocationPoints + bonusPoints;
  }, 0);

  const totalLocationPointsWithBonuses =
    (location?.groupPointsEarned ?? 0) +
    earnedLocationPoints +
    (challengeLocation?.locationSpeedBonus ?? 0);

  return (
    <SafeAreaView
      style={{
        flex: 1,
        justifyContent: 'flex-end',
      }}>
      <TouchableOpacity
        style={{flex: 1}}
        onPress={() =>
          navigationRef?.goBack(
            'ScavengerHunt_v2/Classic/LocationChallengeListModal.tsx',
          )
        }
      />
      <View
        style={{
          backgroundColor: constants?.color?.white,
          width,
          marginTop: 5,
          paddingVertical: 5,
          borderRadius: 10,
          position: 'relative',
        }}>
        {challengeLocation?.completeTime && (
          <View style={{marginTop: 10}}>
            <BodyText
              text={'🎉 Location Complete! 🎉'}
              textStyle={{
                textAlign: 'center',
                fontWeight: 'bold',
                fontSize: 18,
                lineHeight: 20,
                fontFamily: constants?.font.circularBold,
              }}
            />
            <BodyText
              text={'You & Your Team Earned'}
              textStyle={{
                textAlign: 'center',
                fontWeight: '700',
              }}
            />
            <BodyText
              text={`${totalLocationPointsWithBonuses?.toFixed(0)} pts`}
              textStyle={{
                textAlign: 'center',
                fontWeight: '700',
                fontSize: 24,
                lineHeight: 26,
                color: constants?.color?.orange,
              }}
            />
          </View>
        )}
        <View style={{marginTop: challengeLocation?.completeTime ? 0 : 10}}>
          <BodyText
            text={name}
            textStyle={{
              fontWeight: '700',
              textAlign: 'center',
              marginHorizontal: 30,
            }}
          />
          {challengeLocation?.completeTime && (
            <View>
              <BodyText
                text="📊 Location Score Breakdown:"
                textStyle={{
                  lineHeight: 22,
                  fontWeight: '700',
                  textAlign: 'center',
                  marginHorizontal: 20,
                  marginTop: 20,
                  fontFamily: constants?.font.circularBold,
                }}
              />

              <View>
                <BodyText
                  text={`📍 Check-in: ${location?.groupPointsEarned}`}
                  center
                  textStyle={{
                    marginRight: 5,
                    marginHorizontal: 0,
                    color: 'black',
                    lineHeight: 22,
                  }}
                />
                <BodyText
                  text={`🎯 Challenges: ${
                    earnedLocationPoints
                      ? `${formatNumberDisplayAmount(
                          earnedLocationPoints,
                        )}/${formatNumberDisplayAmount(totalLocationPoints)}`
                      : formatNumberDisplayAmount(totalLocationPoints)
                  }`}
                  center
                  textStyle={{
                    marginRight: 5,
                    marginHorizontal: 0,
                    color: 'black',
                    lineHeight: 22,
                  }}
                />
                <BodyText
                  text={`⏱️ Location Speed Bonus: ${challengeLocation.locationSpeedBonus} / 500`}
                  center
                  textStyle={{
                    marginRight: 5,
                    marginHorizontal: 0,
                    color: 'black',
                    lineHeight: 22,
                    marginBottom: 10,
                  }}
                />
              </View>
            </View>
          )}

          {challengeLocation?.completeTime && !!postContent && (
            <HtmlBlock
              html={postContent || ''}
              htmlStyle={{fontSize: 16, lineHeight: 20}}
            />
          )}

          {!challengeLocation?.completeTime && (
            <View
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'row',
                flexWrap: 'wrap',
                paddingHorizontal: 10,
                paddingTop: 20,
              }}>
              <View style={{marginHorizontal: 5}}>
                <ClassicIconButton
                  handlePress={() => {
                    navigationRef?.goBack(
                      'ScavengerHunt_v2/Classic/LocationChallengeListModal.tsx',
                    );
                    navigationRef.navigate(
                      'NavigationChallengeModal',
                      {
                        locationId,
                      },
                      'ScavengerHunt_v2/Classic/LocationChallengeListModal.tsx',
                    );
                  }}
                  size={24}
                  text="Map"
                  icon="map-o"
                  color="blue"
                  iconColor="white"
                  inline
                />
              </View>
              <View style={{marginHorizontal: 5}}>
                <ClassicIconButton
                  text={`${
                    earnedLocationPoints
                      ? `${formatNumberDisplayAmount(
                          earnedLocationPoints,
                        )}/${formatNumberDisplayAmount(totalLocationPoints)}`
                      : formatNumberDisplayAmount(totalLocationPoints)
                  } pts`}
                  handlePress={() => {
                    console.log('handlePress');
                  }}
                  size={24}
                  icon="flash"
                  color="teal"
                  inline
                  iconColor="white"
                />
              </View>
              <View style={{marginHorizontal: 5}}>
                <ClassicIconButton
                  text={`${completedChallenges}/${challengeList?.length} Challenges`}
                  handlePress={() => {
                    console.log('handlePress');
                  }}
                  size={24}
                  icon="compass"
                  color="orange"
                  iconColor="white"
                  inline
                />
              </View>
            </View>
          )}
          {!challengeLocation?.completeTime && (
            <View>
              <BodyText
                text="⏩ Earn More Points by Finishing Challenges Faster!"
                textStyle={{
                  fontSize: 14,
                  lineHeight: 20,
                  fontWeight: '700',
                  textAlign: 'center',
                  marginHorizontal: 20,
                  marginTop: 20,
                  marginBottom: 20,
                  fontFamily: constants?.font.circularBold,
                }}
              />
            </View>
          )}
        </View>

        <CloseIconButton
          handlePress={() =>
            navigationRef?.goBack(
              'ScavengerHunt_v2/Classic/LocationChallengeListModal.tsx',
            )
          }
          side="right"
        />

        {challengeLocation?.completeTime && (
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'center',
              marginBottom: 10,
            }}>
            <TouchableOpacity
              onPress={toggleChallenges}
              style={{
                borderWidth: 1,
                borderColor: constants?.color?.gray2,
                borderRadius: 10,
                width: width - 20,
                marginTop: 10,
                paddingHorizontal: 20,
                paddingVertical: 10,
              }}>
              <Text
                style={{
                  color: constants?.color?.black,
                  fontFamily: constants?.font?.circularBold,
                  textAlign: 'center',
                }}>
                {showChallenges ? 'Hide Challenges' : 'Show Challenges'}
              </Text>
            </TouchableOpacity>
          </View>
        )}
        <Animated.View style={{height: animatedHeight, overflow: 'hidden'}}>
          <ScrollView
            contentContainerStyle={
              challengeLocation?.completeTime ? {paddingBottom: 100} : null
            }
            style={{
              maxHeight: challengeLocation?.completeTime
                ? height - 500
                : height - 360,
              minHeight: height - 520,
            }}>
            {!!challengeList &&
              !!challengeList?.length &&
              challengeList?.map?.((challengeId) => {
                const challenge = allChallenges[challengeId];
                const {
                  type,
                  question,
                  points = 100,
                  groupPointsEarned = 0,
                  bonusScoreData,
                  completeTime,
                } = challenge;

                let photoBonusPoints = 0;
                if (
                  type === 'photo' &&
                  bonusScoreData?.accuracy_score &&
                  bonusScoreData?.fun_of_photo_creativity
                ) {
                  photoBonusPoints =
                    bonusScoreData.accuracy_score +
                    bonusScoreData.fun_of_photo_creativity;
                }

                return (
                  <ClassicChallengeCard
                    key={`c_l_item_${challengeId}${challenge.challengeId}`}
                    containerStyle={
                      challenge.isPlayerChallenge
                        ? {
                            borderWidth: 2,
                            borderColor: challenge.lightningTimerSeconds
                              ? '#FFE266' // Lightning Timer color
                              : type === 'emoji_decoder'
                              ? constants?.color?.teal
                              : type === 'photo'
                              ? constants?.color?.orange
                              : type === 'where_map'
                              ? constants?.color?.blue
                              : constants?.color?.orange, //default orange
                          }
                        : {}
                    }
                    isPlayerChallenge={challenge.isPlayerChallenge || undefined}
                    title={
                      challenge.isPlayerChallenge
                        ? challenge.lightningTimerSeconds
                          ? 'Lightning Challenge'
                          : challenge.name ?? '' // Provide an empty string as fallback
                        : undefined
                    }
                    // bring in colors based on role from sql
                    titleStyle={
                      challenge.isPlayerChallenge
                        ? {
                            fontSize: 22,
                            lineHeight: 22,
                            color: challenge.lightningTimerSeconds
                              ? '#FB9B00'
                              : challenge.type === 'emoji_decoder'
                              ? constants?.color?.teal
                              : challenge.type === 'where_map'
                              ? constants?.color?.blue
                              : challenge.type === 'photo'
                              ? constants?.color?.orange
                              : undefined,
                          }
                        : undefined
                    }
                    text={
                      challenge.isPlayerChallenge ? undefined : type ===
                        'emoji_decoder' ? (
                        challenge?.challengePhoto ? (
                          <ExpoImage
                            style={{
                              height: 50,
                              width: 50,
                            }}
                            resizeMode="cover"
                            source={{
                              uri:
                                cloudFlareOptimizeImage(
                                  challenge?.challengePhoto,
                                ) || '',
                            }}
                          />
                        ) : (
                          <BodyText
                            textStyle={{
                              textAlign: 'center',
                              fontWeight: '400',
                              marginHorizontal: 60,
                              fontSize: 15,
                              lineHeight: 16,
                              fontFamily: 'Jakarta',
                              fontStyle: 'italic',
                            }}
                            text={
                              question && question.match(/\\u[\dA-Fa-f]{4}/)
                                ? JSON.parse('"' + question + '"')
                                : question || ''
                            }
                          />
                        )
                      ) : (
                        question || 'Challenge'
                      )
                    }
                    secondaryText={
                      challenge.isPlayerChallenge
                        ? 'for ' + challenge.playerName || ''
                        : ''
                    }
                    icon={
                      type === 'photo'
                        ? 'camera'
                        : type === 'video'
                        ? 'video-camera'
                        : type === 'location'
                        ? 'map-marker'
                        : type === 'numberguesser' || type === 'number_guesser'
                        ? 'sliders'
                        : type === 'multiple_choice'
                        ? 'university'
                        : type === 'true_false'
                        ? 'question-circle'
                        : type === 'fill_in_the_blank'
                        ? 'braille'
                        : 'university'
                    }
                    iconImage={
                      challenge.isPlayerChallenge
                        ? challenge.lightningTimerSeconds
                          ? require('src/Images/Icons/lightning.png')
                          : type === 'emoji_decoder'
                          ? require('src/Images/Icons/emoji-decoder.png')
                          : type === 'where_map'
                          ? require('src/Images/Icons/guess-where.png')
                          : type === 'photo'
                          ? require('src/Images/Icons/photo-role.png')
                          : null
                        : null
                    }
                    backgroundColor={
                      challenge.isPlayerChallenge &&
                      challenge.lightningTimerSeconds
                        ? '#FFE266' // Timer Limit background color
                        : type === 'photo'
                        ? constants?.color?.orange
                        : type === 'video'
                        ? constants?.color?.blue
                        : type === 'where_map'
                        ? constants?.color?.blue
                        : type === 'location'
                        ? constants?.color?.black_30
                        : type === 'numberguesser' || type === 'number_guesser'
                        ? constants?.color?.yellow
                        : type === 'multiple_choice'
                        ? constants?.color?.teal
                        : type === 'true_false'
                        ? constants?.color?.gray3
                        : type === 'fill_in_the_blank' ||
                          type === 'fill_in_the_blank_traditional'
                        ? constants?.color?.gray2
                        : constants?.color?.teal
                    }
                    label={
                      challenge.isPlayerChallenge &&
                      challenge.lightningTimerSeconds
                        ? '' // No label for player challenges with timer limits
                        : `${
                            completeTime
                              ? (
                                  (groupPointsEarned ?? 0) +
                                  (photoBonusPoints ?? 0)
                                )?.toFixed(0)
                              : points
                          } pts`
                    }
                    completed={!!completeTime}
                    completedIcon={
                      !!groupPointsEarned && groupPointsEarned > 0
                        ? 'check'
                        : 'close'
                    }
                    completedIconColor={
                      !!groupPointsEarned && groupPointsEarned > 0
                        ? constants?.color?.brightgreen
                        : constants?.color?.red
                    }
                    handlePress={() => {
                      handleSelectChallenge(challenge);
                    }}
                    renderEmojiChallenge={
                      !!question && !!question.match(/\\u[\dA-Fa-f]{4}/)
                    }
                  />
                );
              })}
          </ScrollView>
        </Animated.View>

        {!challengeLocation?.completeTime && (
          <BodyText
            text={checkInTimeText}
            textStyle={{
              fontSize: 14,
              lineHeight: 20,
              fontWeight: '700',
              textAlign: 'center',
              marginHorizontal: 20,
              fontFamily: constants?.font.circularBold,
            }}
          />
        )}
      </View>
    </SafeAreaView>
  );
};

export default LocationChallengeListModal;

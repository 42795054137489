import {
  getDatabase,
  getStorage,
  analytics,
  crashlytics,
  Query,
} from 'src/Utils/db';
import {Alert, Platform} from 'react-native';
import 'react-native-get-random-values';

import {AppEventsLogger} from 'src/Modules/Native/Facebook';

import ImageResizer from 'react-native-image-resizer';
import {saveToCameraRoll} from 'src/Utils/cameraHelpers';
import {useEffect, useState} from 'react';

import {mmkvStorage} from 'src/Utils/mmkvStorage';

import {
  deleteFirebaseListener,
  saveFirebaseListener,
} from 'src/Redux/reducers/current_screen.reducer';
import {dispatchAction, getReduxState} from 'src/Utils/helpers';
import moment from 'moment';

import {TUser, isTUser} from 'src/types/TUser';
import {setCustomTag} from '@microsoft/react-native-clarity';

type RefObject = {
  path: string;
  orderBy?: string;
  equalTo: string;
  type?: string;
  multiple?: boolean;
};

type RefObjectOrderBy = {
  path: string;
  orderBy: string;
  equalTo: string;
  type?: string;
  multiple?: boolean;
};

type Quality = {
  width: number;
  height: number;
  quality: number;
};

type ShareLinkInfo = {
  link?: string;
  title?: string;
  description?: string;
};

// removing undefined
// which would cause writes to fail
export function removeUndefinedKeys(item: TJSONValue) {
  try {
    if (typeof item === 'object' && item !== null) {
      for (const key in item) {
        if (key in item) {
          if (typeof item[key] === 'undefined') {
            item[key] = null;
          }
        }
      }
    }
    return item;
  } catch (error: unknown) {
    return item;
  }
}

const checkPathForUndefined = (path = '') => {
  if (path?.includes('undefined')) {
    console.warn('WARNING: Undefined in PATH', {path});
    if (__DEV__) {
      Alert.alert('WARNING: Undefined in PATH', path);
    }
  }
};

export const logForCrashlytics = async (log: string, log_2: unknown = '') => {
  console.log(log, log_2);
  if (Platform.OS !== 'web') {
    try {
      crashlytics()?.log?.(log || 'No log info passed');
    } catch (error: unknown) {
      if (__DEV__) {
        console.log('logForCrashlytics', log);
        Alert.alert(
          'Could not log crashlytics log' + log,
          error instanceof Error ? error.message : String(error),
        );
      }
    }
  } else {
    // console.log('skipping crashlytics log web');
  }
};

export const logAttributesForCrashlytics = (
  key: string = '',
  value: string = '',
) => {
  crashlytics()?.setAttribute?.(String(key), String(value));

  // log attribute for clarity
  try {
    setCustomTag(key, value);
  } catch (error) {
    console.error(
      `Failed to set custom tag for Clarity: ${key}=${value}`,
      error,
    );
  }
};

export const getDataAtOrderBy = async (refObject: RefObjectOrderBy) => {
  logForCrashlytics('getDataAtOrderBy fired in fireUtils');
  const {path, orderBy, equalTo, type, multiple = false} = refObject;

  return await new Promise((resolve) => {
    const dbRef = getDatabase().ref(path);

    dbRef
      .orderByChild(orderBy)
      .equalTo(equalTo)
      .once('value', (snapshot) => {
        const result = snapshot.val?.();

        if (result) {
          if (multiple) {
            console.log('return multiples');
            return resolve(result);
          }

          const firstResult = Object.values(result)[0];

          if (firstResult instanceof Object && type) {
            resolve({...firstResult, type});
          } else {
            resolve(firstResult);
          }
        } else {
          resolve(false);
        }
      });
  });
};

function canBeCastToNumber(value: unknown): boolean {
  if (
    typeof value === 'string' ||
    typeof value === 'number' ||
    typeof value === 'boolean'
  ) {
    return !isNaN(Number(value));
  }
  return false;
}

type DataType = 'number' | 'string';

// Function overloads
// allowing for dynamic typing
// eslint-disable-next-line
export function getDataAtPath(path: string, type: 'number'): Promise<number>;
// eslint-disable-next-line
export function getDataAtPath(path: string, type: 'string'): Promise<string>;
// eslint-disable-next-line
export function getDataAtPath(path: string): Promise<unknown>;

// Implementation
export function getDataAtPath(
  path: string,
  type?: DataType,
): Promise<number | string | TJSONValue> {
  logForCrashlytics('getDataAtPath fired in fireUtils', path);

  return new Promise<number | string | unknown>((resolve, reject) => {
    const dbRef = getDatabase().ref(path);
    try {
      dbRef?.once('value', (snapshot) => {
        const data = snapshot?.val?.();

        if (type === 'number') {
          if (canBeCastToNumber(data)) {
            resolve(Number(data));
          } else {
            resolve(0);
          }
        } else if (type === 'string') {
          String(data);
        } else {
          resolve(data);
        }
      });
    } catch (getDataAtPathError) {
      if (__DEV__) {
        Alert.alert('getDataAtPathError', path);
      }
      console.log({getDataAtPathError});
      reject(getDataAtPathError);
    }
  });
}

export const getFirebaseUserInfoById = async (
  userId: string,
): Promise<TUser | null> => {
  try {
    if (!userId) {
      console.error('no user id passed to getCurrentUserInfo');
      return null;
    }
    const user = await getDataAtPath(`users/${userId}/`);
    if (!user) {
      console.error('no user found', {user});
      return null;
    }
    if (!isTUser(user)) {
      console.error('user is not a TUser', {user});
      // log error but return user
      // this correct an issue where slight difference in the type of the user
      // result in no user being returned
    }

    return user as TUser;
  } catch (getUserInfoByIdError) {
    console.error({getUserInfoByIdError});
    return null;
  }
};

export const getDataAtJoinRef = async (refObject: RefObject) => {
  logForCrashlytics('getDataAtJoinRef fired in fireUtils', {refObject});
  const {path, equalTo, type} = refObject;

  return await new Promise(async (resolve) => {
    const fullPath = `${path}/${equalTo}`;
    checkPathForUndefined(fullPath);
    const result = await getDataAtPath(fullPath);
    if (result) {
      console.log({result});
      resolve({...result, type});
    } else {
      console.log('no matches were found: ' + fullPath);
      resolve(false);
    }
  });
};

/**
 * Returns null if no data
 * An array of objects if querying data, aka using order byKey (because finds multiple matches)
 * Return a single object if not querying data, aka not using orderByKey (because finds one match)
 * an object users/${userId}
 * an array users/ orderByKey groupId = ${groupId}
 */
export const useFirebaseData = (
  path: string,
  pathEffect?: ((_data: TJSONValue) => void) | null,
  orderByKey?: string | null,
  orderByValue?: string | number | null,
  hideLogs: boolean = false,
  isOff: boolean = false,
  showNullData: boolean = false,
  sortOrder?: 'asc' | 'desc',
  sortLimit?: number,
): TJSONArray | TJSONObject | null => {
  const [firebaseData, setFirebaseData] = useState<TJSONValue>();

  useEffect(() => {
    if (isOff || path?.includes('/null') || path?.includes('/error')) {
      return console.log('listener is off, isOff not met: ' + path);
    }
    const listenerId = Math.round(Math.random() * 1000000000);

    let dataRef: Query;
    if (!!orderByKey && !!orderByValue) {
      dataRef = getDatabase()
        .ref(path)
        .orderByChild(orderByKey)
        .equalTo(orderByValue);
    } else if (!!orderByKey && !!sortOrder && !!sortLimit) {
      dataRef = getDatabase().ref(path).orderByChild(orderByKey);
      if (sortOrder === 'asc') {
        dataRef = dataRef.limitToFirst(sortLimit);
      } else {
        dataRef = dataRef.limitToLast(sortLimit);
      }
    } else {
      dataRef = getDatabase().ref(path);
    }

    !hideLogs &&
      console.log('creating data ref', {
        path,
        orderByKey,
        orderByValue,
        pathEffect,
      });
    dispatchAction(saveFirebaseListener({[listenerId]: path}));
    const onChange = dataRef.on(
      'value',
      async (snapshot) => {
        const newData = await snapshot.val?.();
        console.log('useFirebaseData newData ' + path);

        // handling null condition
        // so data update on delete
        if (!newData && pathEffect) {
          pathEffect(newData);
        }

        setFirebaseData(newData);
      },
      (useFirebaseDataError) => {
        if (__DEV__) {
          Alert.alert('useFirebaseDataError', path);
        }
        console.log({useFirebaseDataError, path});
      },
    );

    return () => {
      console.log('removing listeners: ' + listenerId, {onChange});
      try {
        dataRef?.off?.('value', onChange);
        dispatchAction(deleteFirebaseListener(listenerId));
        // setFirebaseData(null);
      } catch (listenerRemoveError) {
        //
        console.error('error removing listener', {listenerRemoveError});
      }

      setFirebaseData(undefined);
    };
  }, [path, !!orderByKey, orderByValue, hideLogs, isOff]);

  useEffect(() => {
    if (pathEffect && (!!firebaseData || orderByKey || showNullData)) {
      // !hideLogs &&
      //   console.log('firing path effect', {path, pathEffect, firebaseData});
      pathEffect(firebaseData);
    }
  }, [JSON.stringify(firebaseData), !!pathEffect]);

  return firebaseData;
};

export async function setDataAtPath(path: string, data: TJSONValue = null) {
  logForCrashlytics('setDataAtPath fired in fireUtils', {path});
  checkPathForUndefined(path);
  try {
    await getDatabase().ref(path).set(removeUndefinedKeys(data));
  } catch (setDataAtPathError) {
    if (__DEV__) {
      Alert.alert('setDataAtPathError', path);
    }
    console.log({setDataAtPathError});
  }
}

export async function removeDataAtPath(path: string) {
  logForCrashlytics('removeDataAtPath fired in fireUtils', path);
  checkPathForUndefined(path);
  try {
    await getDatabase().ref(path).remove();
  } catch (removeDataAtPathError) {
    if (__DEV__) {
      Alert.alert('removeDataAtPathError', path);
    }
    console.log({removeDataAtPathError});
  }
}

export async function updateDataAtPath(path: string, data: TJSONValue = null) {
  // eliminating fast firing logs
  if (!path?.includes('device') && !path?.includes('userScreenShot')) {
    logForCrashlytics('updateDataAtPath fired in fireUtils', {path});
    if (__DEV__) {
      console.log({path, data});
    } else {
      console.log({path});
    }
  }

  try {
    checkPathForUndefined(path);

    if (path == 'users/' || path == '/users/') {
      return;
    }
    if (typeof data != 'object') {
      await getDatabase().ref(path).set(removeUndefinedKeys(data));
    } else {
      // fixing undefined keys
      // setting as null
      const parsedData = {...(data || {})};
      Object.keys(parsedData)?.map?.((key) => {
        if (!parsedData?.[key]) {
          parsedData[key] = null;
        }
      });
      await getDatabase().ref(path).update(parsedData);
    }
  } catch (updateDataAtPathError) {
    if (__DEV__) {
      Alert.alert('updateDataAtPathError', path);
    }
    console.log({updateDataAtPathError, path});
  }
}

export async function updateMultiDataAtPaths(updates: {
  [path: string]: TJSONValue;
}) {
  try {
    // Log paths and data for debugging
    logForCrashlytics('updateMultiDataAtPaths fired in fireUtils', updates);
    if (__DEV__) {
      console.log(updates);
    }

    // Checking each path for undefined and sanitizing data
    for (const path in updates) {
      checkPathForUndefined(path);
      if (typeof updates[path] === 'object' && updates[path] !== null) {
        // Sanitize object data: set undefined properties to null
        Object.keys(updates[path]).forEach((key) => {
          if (updates[path][key] === undefined) {
            updates[path][key] = null;
          }
        });
      } else if (updates[path] === undefined) {
        // Sanitize undefined scalar data: set to null
        updates[path] = null;
      }
    }

    // Perform the multi-path update
    await getDatabase().ref().update(updates);
  } catch (updateMultiDataAtPathsError) {
    // Handle any errors that occur during the update
    if (__DEV__) {
      console.error(
        'updateMultiDataAtPathsError',
        updateMultiDataAtPathsError,
        updates,
      );
    }
    logForCrashlytics('updateMultiDataAtPathsError in fireUtils', {
      updateMultiDataAtPathsError,
      updates,
    });
  }
}

export async function uploadMedia(
  imagePath: string,
  uri: string,
  isVideo: boolean,
  doSaveToCameraRoll = false,
  hideLogs = false,
  hideErrors = false,
  quality: Quality = {
    width: 1200,
    height: 2000,
    quality: 30,
  },
) {
  !hideLogs && logForCrashlytics('uploading media');
  !hideLogs && console.log({imagePath});
  !hideLogs && console.log({imagePath, uri, isVideo, doSaveToCameraRoll});
  if (imagePath == 'users/' || imagePath == '/users/' || !imagePath || !uri) {
    console.log('error');
    return;
  }

  checkPathForUndefined(imagePath);

  let finalUri = uri;

  try {
    if (!isVideo) {
      const croppedImage = await ImageResizer.createResizedImage(
        uri,
        quality?.height || 1200,
        quality?.width || 2000,
        'JPEG',
        quality?.quality || 60,
      );
      finalUri = croppedImage?.uri;
      if (doSaveToCameraRoll) {
        saveToCameraRoll(uri, isVideo ? 'video' : 'photo', false);
      }
    }
  } catch (error: unknown) {
    console.log('an error with resizing occurred', error);
    Alert.alert(
      'error',
      error instanceof Error ? error.message : String(error),
    );
  }

  let photoUploadStep = 0;
  try {
    const storage = getStorage();
    const imageRef = storage.ref(imagePath);
    photoUploadStep = 1;
    let downloadURL;
    if (!uri?.includes?.('firebase')) {
      await imageRef?.putFile(finalUri);
      photoUploadStep = 2;
      downloadURL = await imageRef?.getDownloadURL();
      photoUploadStep = 3;
      if (!downloadURL?.includes?.('screenShot')) {
        console.log({photoUploadStep, downloadURL});
      }
    } else {
      // download is skipped
      downloadURL = uri;
    }
    if (!downloadURL?.includes?.('screenShot')) {
      console.log('uploading media done', {downloadURL});
    }
    return downloadURL;
  } catch (error: unknown) {
    console.warn('photoUploadError', {photoUploadStep, uri, error});
    !hideErrors &&
      Alert.alert(
        'Photo Could Not Be Uploaded',
        'Please try again. Photo uploadMedia Error Code: ' +
          photoUploadStep +
          `\b uri: ${uri} + \b ${
            error instanceof Error ? error.message : String(error)
          }`,
      );
    return null;
  }
}

export const logSaleData = async (
  amountDollars: number,
  type = 'Coin_Purchase',
) => {
  const userId = getReduxState((state) => state?.user?.userId);
  const time = moment().format('YYYY-MM-DDTHH:mm:ss');
  const transactionId = userId + '-' + time;
  logForCrashlytics('logSaleData fired in fireUtils');
  console.log(transactionId);
  try {
    await AppEventsLogger.logPurchase(amountDollars, 'USD', {
      param: 'value',
      contentType: type,
      transactionId,
    });
    await analytics().logPurchase({
      currency: 'USD',
      items: [{item_name: type, quantity: amountDollars}],
      value: amountDollars,

      transaction_id: transactionId,
    });
    const deviceUUID = await mmkvStorage.getString('deviceUUID');
    setDataAtPath(`devices/${deviceUUID || 'error'}/hasMadePurchase`, true);
    setDataAtPath(`users/${userId || 'error'}/hasMadePurchase`, true);
    setDataAtPath(
      `users/${userId || 'error'}/googleAnalyticsPurchaseLogged`,
      true,
    );
  } catch (error: unknown) {
    Alert.alert(
      JSON.stringify(error instanceof Error ? error.message : String(error)),
    );
  }
};

export async function create_short_link({
  link = 'https://www.letsroam.com/',
  title = 'Join my group with my easy join code!',
  description = 'Click on this link and it will auto add my code!',
}: ShareLinkInfo) {
  const api_key = 'YOUR_API_KEY_HERE'; // Replace with your API key

  const dynamicLinkInfo = {
    dynamicLinkInfo: {
      domainUriPrefix: 'https://letsroam.page.link',
      link: link,
      androidInfo: {
        androidPackageName: 'com.barhuntv2',
      },
      iosInfo: {
        iosBundleId: 'com.letsroam.www',
        iosAppStoreId: '1338916789',
      },
      socialMetaTagInfo: {
        socialTitle: title,
        socialDescription: description,
        socialImageLink:
          'https://www.letsroam.com/assets/img/Scavenger_hunt_app.jpg',
      },
    },
    suffix: {
      option: 'SHORT',
    },
  };
  const url = `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${api_key}`;
  console.log({url});

  let apiResponse;

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(dynamicLinkInfo),
    });
    console.log('The share link api response is ');
    if (response.status == 200) {
      apiResponse = await response.json?.();
      const shortLink = apiResponse?.shortLink;

      // console.log({shortLink});

      return shortLink;
    }
  } catch (deepLinkError) {
    console.error({deepLinkError});
    // if (__DEV__) {
    //   Alert.alert('The url failed to generate: ', error.message);
    // }
    apiResponse = {};
    return undefined;
  }
}

// DOCS https://rnfirebase.io/docs/v5.x.x/links/reference/DynamicLink
export async function createUserShareLink(userId: string) {
  logForCrashlytics('createShareLink fired in fireUtils');

  const newLink = await create_short_link({
    link: 'https://letsroam.com?action=gift&userId=' + userId,
    title: `A Let's Roam Scavenger Hunt is an epic adventure. Follow this link and get 30% off!`,
  });

  const path = `users/${userId}/shareLink`;

  setDataAtPath(path, newLink);

  return console.log({newLink});
}

// DOCS https://rnfirebase.io/docs/v5.x.x/links/reference/DynamicLink
export async function createGroupShareLink({
  code,
  userId,
  groupId,
}: {
  code?: string;
  userId?: string;
  groupId?: string;
}) {
  logForCrashlytics('createGroupShareLink fired in fireUtils');
  // Alert.alert('hi')
  console.log('createGroupShareLink fired', {code, userId, groupId});

  // console.log('The long dynamic link is ', link);

  const newLink = await create_short_link({
    link: 'https://www.letsroam.com/?join_code=' + code,
    title: `Join my group with the code: ${code}`,
  });

  let path = null;

  if (userId) {
    path = `users/${userId}/easyUserLevelJoinCode`;
  } else if (groupId) {
    path = `scavengerhunt/groups/${groupId}/easyGroupLevelJoinCode`;
  } else {
    return console.log('error');
  }

  if (!newLink || !path) {
    return console.log('error');
  }

  setDataAtPath(path, newLink);

  return console.log({newLink});
}

// BACKUP IF LINK API FAILS
export const createAndroidShareLink = async () => {
  logForCrashlytics('createAndroidShareLink fired in fireUtils');
  try {
    const api_key = 'YOUR_API_KEY_HERE'; // Replace with your API key
    const url = `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${api_key}`;

    const response = await fetch(url, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        // dynamicLinkInfo,
        suffix: {
          option: 'SHORT',
        },
      }),
    });
    console.log('The share link api response is ', response);
    if (response.status == 200) {
      return await response.json?.();
    }
  } catch (deepLinkError) {
    console.error({deepLinkError});
    // if (__DEV__) {
    //   Alert.alert('The url failed to generate: ', error.message);
    // }
  }
};

// ga4 react native firebase analytics tracking
export const logCustomAnalyticsEvent = (
  eventName: string,
  params?: TJSONValue,
) => {
  if (Platform.OS != 'web') {
    let formattedParams: Record<string, string | number | null> = {
      userId: mmkvStorage.getString('userId') || null,
      groupId: mmkvStorage.getString('groupId') || null,
      deviceUUID: mmkvStorage.getString('deviceUUID') || null,
    };
    if (typeof params === 'object' && params !== null) {
      formattedParams = {...formattedParams, ...removeUndefinedKeys(params)};
    }
    try {
      analytics().logEvent(eventName, formattedParams);
    } catch (logCustomEventError) {
      console.error({logCustomEventError});
    }
  } else {
    // console.log('skipping analytics log web');
  }
};

export default {
  logSaleData,
  getDataAtPath,
  getDataAtOrderBy,
  getDataAtJoinRef,
  setDataAtPath,
  updateDataAtPath,
  createGroupShareLink,
  createUserShareLink,
  logForCrashlytics,
};

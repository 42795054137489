import {navigationRef} from 'src/Nav/navigationHelpers';

import {Alert, Platform} from 'react-native';
import {v4 as uuidv4} from 'uuid';

import {GoogleSignin} from 'src/Utils/googleAuth';
import {getDatabase, messaging, getAuth, analytics} from 'src/Utils/db';

import * as appleAuth from 'expo-apple-authentication';

import {logForCrashlytics} from 'src/Utils/fireUtils';

import {mmkvStorage} from 'src/Utils/mmkvStorage';
import {apiTrackUserLogin} from 'src/Utils/apiCalls';
import {
  createUserShareLink,
  logCustomAnalyticsEvent,
} from 'src/Utils/fireUtils';
import {
  dispatchAction,
  getReduxState,
  randomPhotoColor,
} from 'src/Utils/helpers';
import {saveUserInfo} from 'src/Redux/reducers/user_info.reducer';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {LoginManager, Profile, FBProfile} from 'src/Modules/Native/Facebook';
import {TUser, isTUser} from 'src/types/TUser';
import {TUserLogin} from 'src/types/TUserLogin';
import {setCustomUserId} from '@microsoft/react-native-clarity';

export const configureGoogleSignIn = () => {
  // logForCrashlytics('configureGoogleSignIn fired in loginFunctions')
  try {
    GoogleSignin.configure({
      webClientId:
        '472107255951-0tdfl6pp1tc67mlgkcvrt5dt3cjtatqq.apps.googleusercontent.com',
    });
    console.log('google sign in configured');
  } catch (err) {
    console.log('Sign in failed', {err});
  }
};

export const tryGoogleLogin = async () => {
  logForCrashlytics('tryGoogleLogin fired in loginFunctions');

  if (Platform.OS == 'web') {
    console.log('trying web logging');
    try {
      await GoogleSignin.hasPlayServices();
      const userInfo = await GoogleSignin.signIn();
      if (
        Platform.OS == 'web' &&
        !userInfo?.data?.user?.email?.includes('@lets')
      ) {
        return alert(
          `You can only sign in with your Let's Roam email for web demos. Please refresh your screen!`,
        );
      }
      return userInfo;
    } catch (err) {
      console.log('web login failed', {err});
      console.log(
        'upload sha1 keystore to https://console.firebase.google.com/u/0/project/barhuntv2/settings/general/android:com.barhuntv2',
      );
      console.log(
        'https://console.firebase.google.com/u/0/project/barhuntv2/settings/general/android:com.barhuntv2',
      );
      console.log(
        'keytool -list -v -keystore ~/.android/debug.keystore -alias androiddebugkey -storepass android -keypass android',
      );
      console.log(
        'keytool -list -v -keystore ~/.android/debug.keystore -alias androiddebugkey -storepass android -keypass android',
      );
      console.error(err);
      throw new Error();
    }
  } else {
    try {
      console.log('native google signin');
      await GoogleSignin.hasPlayServices();
      const userInfo = await GoogleSignin.signIn();
      console.log('google info', {userInfo});
      return userInfo;
    } catch (GoogleSigninError) {
      console.log(
        'upload sha1 keystore to https://console.firebase.google.com/u/0/project/barhuntv2/settings/general/android:com.barhuntv2',
      );
      console.log(
        'https://console.firebase.google.com/u/0/project/barhuntv2/settings/general/android:com.barhuntv2',
      );
      console.log(
        'keytool -list -v -keystore ~/.android/debug.keystore -alias androiddebugkey -storepass android -keypass android',
      );
      console.error('google sign in error', {GoogleSigninError});
      throw new Error();
    }
  }
};

export const tryGoogleLogout = async () => {
  logForCrashlytics('tryGoogleLogout fired in loginFunctions');
  await GoogleSignin.signOut();
  await getAuth().signOut();
};

const genUUID = () => {
  return uuidv4();
};

export const tryAppleLogin = async () => {
  logForCrashlytics('tryAppleLogin fired in loginFunctions');
  try {
    await GoogleSignin.signOut();
    await getAuth().signOut();
  } catch (err) {
    console.log(err);
  }

  console.log('try apple login');
  try {
    const appleAuthRequestResponse = await appleAuth.signInAsync({
      requestedScopes: [
        appleAuth.AppleAuthenticationScope.FULL_NAME,
        appleAuth.AppleAuthenticationScope.EMAIL,
      ],
    });

    // Ensure Apple returned a user identityToken
    if (!appleAuthRequestResponse.identityToken) {
      throw 'Apple Sign-In failed - no identify token returned';
    }

    console.log_on_production({appleAuthRequestResponse});

    // Create a Firebase credential from the response
    const {email} = appleAuthRequestResponse;

    return {
      email,
      contactEmail: email,
      contactPhone: '+1234567890',
      birthday: '2019-01-01',
      home_zip_code: '10000',
    };
  } catch (err) {
    console.log('an error occurred');
    console.log(err);
  }
};

export async function tryFacebookLogin(): Promise<typeof FBProfile | null> {
  logForCrashlytics('tryFacebookLogin fired in loginFunctions');

  console.log('try facebook login');
  try {
    const result = await LoginManager.logInWithPermissions([
      'public_profile',
      'email',
    ]);

    console.log('the result is', result);
    if (result.isCancelled) {
      throw result;
    }

    const currentProfile = await Profile.getCurrentProfile().then();

    return currentProfile;
  } catch (err) {
    console.log('an error occurred');
    console.log(err);
  }
  return Promise.resolve(null);
}

export const tryFacebookLogout = () => {
  logForCrashlytics('tryFacebookLogout fired in loginFunctions');
  try {
    return LoginManager.logOut();
  } catch (err) {
    console.log(err);
  }
};

export const tryFirebaseLogout = () => {
  logForCrashlytics('tryFirebaseLogout fired in loginFunctions');
  try {
    getAuth().currentUser && getAuth().signOut();
  } catch (err) {
    console.log(err);
  }
};

export const firebaseFindUser = async (
  email: string,
): Promise<TUser | null> => {
  logForCrashlytics('firebaseFindUser fired in loginFunctions');
  try {
    const response = await getDatabase()
      .ref()
      .child('users')
      .orderByChild('email')
      .equalTo(email)
      .once('value');
    if (response.val()) {
      console.log('matching user found!!');
      // soft type checking
      const value = response.val() as TUser[];
      const potentialUser = value[0] as TUser;

      if (!potentialUser) {
        return null;
      }

      isTUser(potentialUser, true);

      return potentialUser;
    } else {
      return null;
    }
  } catch (err) {
    console.log(err);
  }
  return null;
};

export const firebaseFindUserByContactEmail = async (
  contactEmail: string,
): Promise<TUser | null> => {
  logForCrashlytics('firebaseFindUserByContactEmail fired in loginFunctions');
  console.log({contactEmail});
  try {
    const response = await getDatabase()
      .ref()
      .child('users')
      .orderByChild('contactEmail')
      .equalTo(contactEmail)
      .once('value');
    if (response.val()) {
      console.log('matching user found!!', {'matching user': response.val()});
      // soft type checking
      let potentialUser: TUser | null = null;
      try {
        const potentialUsers = response.val() as Record<string, TUser>;
        potentialUser = Object.values(potentialUsers)[0] as TUser;
      } catch (parseUserError) {
        console.error({parseUserError});
      }

      if (!potentialUser) {
        return null;
      }

      isTUser(potentialUser, true);

      return potentialUser;
    } else {
      console.log('no matching users found');
      return null; // Return null instead of false
    }
  } catch (err) {
    console.log(err);
    return null; // Return null in case of an error
  }
};

export const firebaseLoginAnonymously = async (handleDone: Function) => {
  navigationRef.navigate(
    'ConfirmModal',
    {
      titleText: `Do you want to sign in anonymously to your event?`,
      titleBodyText: 'What is your first name or a nick name for your event',
      confirmText: 'Sign In',
      showPrompt: true,
      cancelText: 'Cancel',
      handleConfirm: async (newName, __, handleClose) => {
        if (!newName) {
          return Alert.alert('Please create a nick name');
        }
        try {
          const {user} = await getAuth().signInAnonymously();
          // eslint-disable-next-line no-use-before-define
          await loginAndCreateUser({
            email: `anonymous-${user.uid}`,
            contactEmail: `anonymous-${user.uid}`,
            firstName: newName,
          });
          console.log('User signed in anonymously');
          handleClose();
          handleDone();
        } catch (error: unknown) {
          if (
            error instanceof Object &&
            'code' in error &&
            error.code === 'auth/operation-not-allowed'
          ) {
            console.log('Enable anonymous in your firebase console.');
          }

          console.error(error);
        }
      },
    },
    'loginFunctions 1234',
  );
};

export const firebaseFindUserByContactPhone = async (
  contactPhone: string,
): Promise<TUser | null> => {
  logForCrashlytics('firebaseFindUserByContactPhone fired in loginFunctions', {
    contactPhone,
  });

  try {
    const response = await getDatabase()
      .ref()
      .child('users')
      .orderByChild('contactPhone')
      .equalTo(contactPhone)
      .once('value');
    if (response.val()) {
      console.log('matching user found!!');
      // soft type checking
      let potentialUser: TUser | null = null;
      try {
        const potentialUsers = response.val() as Record<string, TUser>;
        potentialUser = Object.values(potentialUsers)[0] as TUser;
      } catch (parseUserError) {
        console.error({parseUserError});
      }

      if (!potentialUser) {
        return null;
      }

      isTUser(potentialUser, true);

      return potentialUser;
    } else {
      console.log('no matching users found');
      return null;
    }
  } catch (err) {
    console.log(err);
  }
  return null;
};

export const firebaseSetUserFCMToken = async (
  userId: string = 'error',
  fcmToken: string,
) => {
  logForCrashlytics('firebaseSetUserFCMToken fired in loginFunctions');
  try {
    getDatabase().ref('users').child(userId).child('fcmToken').set(fcmToken);
  } catch (err) {
    console.log(err);
  }
};

export const firebaseLoginWithPhone = (phoneNumber: string = '') => {
  logForCrashlytics('firebaseLoginWithPhone fired in loginFunctions', {
    phoneNumber,
  });
  console.log('phone number');
  if (phoneNumber) {
    try {
      if (phoneNumber.includes('8089624')) {
        getAuth().settings.appVerificationDisabledForTesting = true;
      }

      const phoneLoginSettings = getAuth().settings;
      console.log({phoneLoginSettings});
      return getAuth().signInWithPhoneNumber(phoneNumber || '');
    } catch (err) {
      console.log_on_production('firebase error');
      console.warn(err);
    }
  }
  return Promise.resolve(null);
};

export const firebaseSubscribeMessaging = (topicId: string) => {
  logForCrashlytics('firebaseSubscribeMessaging fired in loginFunctions');
  try {
    console.log('firebaseSubscribeMessaging', {topicId});
    if (topicId) {
      messaging()
        .subscribeToTopic(topicId)
        .then((data) =>
          console.log('we registered in with data ', {data, topicId}),
        )
        .catch((err) => console.log('we could not register', err.message));
    }
  } catch (err) {
    console.log(err);
  }
};

export const firebaseSetUserInfo = (userId: string, userInfo: TUser) => {
  logForCrashlytics('firebaseSetUserInfo fired in loginFunctions');
  try {
    getDatabase().ref('users').child(userId).set(userInfo);
  } catch (err) {
    console.log(err);
  }
};

const setLoginItems = async (userId: string, userInfo: TUser) => {
  const latitude =
    getReduxState((state) => state?.location?.region?.latitude) || 0;
  const longitude =
    getReduxState((state) => state?.location?.region?.longitude) || 0;

  mmkvStorage.set('userId', userId);
  mmkvStorage.set('userInfo', JSON.stringify(userInfo));
  AsyncStorage.setItem('userId', userId);
  try {
    analytics().logLogin({method: 'app'});
    analytics().setUserId(userId);
    apiTrackUserLogin({user_id: userId, latitude, longitude});
  } catch (error: unknown) {
    console.log({error});
  }
  try {
    userInfo && !userInfo?.shareLink && createUserShareLink(userId);
  } catch (error: unknown) {
    // Alert.alert('error', JSON.stringify(error));
  }
  dispatchAction(saveUserInfo(userInfo));

  // setting userId for clarity
  try {
    setCustomUserId(userId);
    console.log('Successfully set custom user ID for Clarity');
  } catch (error) {
    console.error('Failed to set custom user ID for Clarity:', error);
  }

  // CHECK BOTH NAV ACTIONS
};

export const loginAndCreateUser = async ({
  email = '',
  contactEmail = '',
  contactPhone = '',
  firstName = '',
  lastName = '',
  googleId = '',
  photoUrl = '',
  birthday,
  home_zip_code,
  contactPhoneNonLogin,
}: TUserLogin) => {
  const deviceUUID = mmkvStorage.getString('deviceUUID');

  console.log('loginAndCreateUser', {
    email,
    firstName,
    contactEmail,
    contactPhone,
  });
  const playTestState = getReduxState(
    (state) => state?.app_info?.playTestState,
  );

  if (!email && !contactEmail && !contactPhone) {
    console.error('no email or contact email or contact phone');
    return null;
  }

  let letsRoamUser = null;

  if (contactPhone) {
    letsRoamUser = await firebaseFindUserByContactPhone(contactPhone);
  }

  // if contactPhone didn't return a user and we have an email
  // lets check for that
  if (!letsRoamUser?.userId && (contactEmail || email)) {
    letsRoamUser = await firebaseFindUserByContactEmail(
      contactEmail || email || 'error@error.com',
    );
  }

  console.log({contactPhone, contactEmail, letsRoamUser});
  const fcmToken = await mmkvStorage.getString('fcmToken');

  // logging in user if exists
  if (letsRoamUser?.userId) {
    const {userId} = letsRoamUser;
    if (!!fcmToken && fcmToken != letsRoamUser?.fcmToken) {
      firebaseSetUserFCMToken(userId, fcmToken);
    }
    userId && !playTestState && firebaseSubscribeMessaging(userId);
    /// this should be helper as well

    logCustomAnalyticsEvent('user_re_login', {
      userId: userId,
      email,
      firstName,
    });
    setLoginItems(userId, letsRoamUser as TUser);

    return letsRoamUser;

    // else creating a new user
  } else {
    console.log('creating a new user');
    const userId = `${genUUID()}`.substring(0, 6).toLowerCase?.();
    userId && !playTestState && firebaseSubscribeMessaging(userId);
    // console.log('The user id is ', userId)
    const userInfo: TUser = {
      userId,
      deviceUUID,
      prepaidJoinCode: `${userId}`.toLowerCase?.(),
      email: email || 'error',
      contactEmail,
      contactPhone: contactPhone || contactPhoneNonLogin || null,
      firstName: firstName || 'User',
      lastName: lastName || 'User',
      googleId: googleId || '',
      photoUrl: photoUrl || null,
      socialPhotoUrl: photoUrl || null,
      birthday,
      home_zip_code,
      photoColor: randomPhotoColor(),
      coins: 0,
      contactPhoneNonLogin: contactPhoneNonLogin || null,

      tickets: {
        Bachelor: 0,
        Bachelorette: 0,
        Birthday: 0,
        'Date Night': 0,
        Standard: 0,
      },
      level: 1,
      loggedIn: true,
      source: 'lr_app',
    };

    logCustomAnalyticsEvent('user_first_login', {
      userId: userId,
      email,
      firstName,
    });

    const referral = await mmkvStorage.getString('referral');

    if (referral) {
      userInfo.referral = JSON.parse(referral);
      userInfo.coins = 300;
    }

    if (fcmToken) {
      userInfo.fcmToken = fcmToken;
    }
    firebaseSetUserInfo(userId, userInfo);
    setLoginItems(userId, userInfo);

    return userInfo;
  }
};

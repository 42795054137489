// Define the Hunt interface with optional properties
import * as t from 'io-ts';
import {validateResult} from 'src/types/typesHelpers';
import {TLocationIO} from './TLocation';

export const THuntIO = t.intersection([
  t.type({
    name: t.union([t.string, t.undefined, t.null]),
    hunt_id: t.string,
    huntMediumPhotoURL: t.union([t.string, t.null]),
    is_free: t.union([t.string, t.boolean]),
    route_id: t.union([t.string, t.null]),
    hunt_version: t.string,
    hunt_type: t.string,
    city: t.union([t.string, t.null]),
    history_focus: t.string,
    culture_focus: t.string,
    difficulty_focus: t.string,
    state: t.union([t.string, t.null]),
    country: t.union([t.string, t.null]),
    art_focus: t.union([t.string, t.null]),
    starting_location: t.union([t.string, t.null]),
    description: t.union([t.string, t.null]),
    distance_miles: t.string,
    lat: t.union([t.number, t.null]), // keep this as only number or null
    long: t.union([t.number, t.null]), // keep this as only number or null
    huntPhotoURL: t.union([t.string, t.null]),
    total_reviews: t.number,
    bars: t.union([t.string, t.null]),
    url: t.union([t.string, t.null]),
    long_description: t.union([t.string, t.null]),
    star_rating: t.number,
    classic_hunt: t.boolean,
    key: t.union([t.string, t.null]),
  }),
  t.partial({
    isTestHunt: t.boolean, // used for testing
    huntLength: t.union([t.string, t.null]),
    joinCode: t.union([t.string, t.null]),
    teamName: t.string, // used for pre-giving name
    huntStarted: t.boolean, // used for testing
    routeIdOverride: t.union([t.string, t.null]), // used for connected hunt
    eventId: t.union([t.string, t.null]), // used for corporate hunt
    theme: t.union([t.string, t.null]), // used for corporate hunt
    featured_locations: t.union([t.string, t.null]),
    wander_list_locations: t.union([t.array(TLocationIO), t.null]), // these are wanderlist locations
    lr_long_description: t.union([t.string, t.null]),
    distanceAway: t.union([t.number, t.null]), //used for sorting hunts
    event_start_time: t.union([t.string, t.null]),
    event_end_time: t.union([t.string, t.null]),
    favorite_count: t.union([t.number, t.null]),
    hasHostControl: t.union([t.boolean, t.null]),
    showAnswers: t.union([t.boolean, t.null]),
    currentLocationId: t.union([t.string, t.null]),
    enablePlayerChallenges: t.union([t.boolean, t.number, t.null]),
    hidePhotos: t.union([t.boolean, t.number, t.null]),
    hideLeaderboard: t.union([t.boolean, t.number, t.null]),
    hideChallengeFeedback: t.union([t.boolean, t.number, t.null]),
    hideInstaShare: t.union([t.boolean, t.number, t.null]),
    hideFbShare: t.union([t.boolean, t.number, t.null]),
    brandingPhoto: t.union([t.string, t.null]),
    branding: t.partial({
      eventIntro: t.string,
      eventName: t.string,
      eventColor: t.string,
    }),
  }),
]);

// Derive the TypeScript type from the io-ts type
export type THunt = t.TypeOf<typeof THuntIO>;

export const isTHunt = (input: unknown): input is THunt => {
  return validateResult(input, THuntIO, 'THunt', false);
};

import moment from 'moment';
import 'react-native-get-random-values';
import {v4 as uuidv4} from 'uuid';

import {updateDataAtPath, logForCrashlytics} from 'src/Utils/fireUtils';
import {getReduxState} from 'src/Utils/helpers';

import {getDataAtPath} from 'src/Utils/fireUtils';

export interface PhotoCompletionObject {
  shareUUID: string;
  groupId: string;
  eventId: string | null;
  userId: string;
  email: string | undefined;
  teamName: string | undefined;
  completionId: string;
  challenge: string;
  type: string;
  date: number;
  is_test: boolean;
  dateFormated: string;
  downloadURL: string;
  photoUrl: string;
  thumbnail: string;
  path: string;
}

export const classicPhotoVideoCompletionEvent = async ({
  challengeId,
  photoUrl,
  thumbnail = '',
  shareUUID,
  description,
  overridePointsEarned,
  isPlayerChallenge,
}: {
  challengeId: string;
  photoUrl: string;
  thumbnail?: string;
  shareUUID: string;
  description: string;
  overridePointsEarned?: number;
  isPlayerChallenge?: boolean;
}): Promise<void> => {
  logForCrashlytics(
    'classicPhotoVideoCompletionEvent fired in huntFunctions_v2',
  );
  console.log('logging classicPhotoVideoCompletionEvent');

  const eventId = getReduxState((state) => state?.group?.info?.eventId);
  const groupId = getReduxState((state) => state?.group?.info?.groupId);
  const userId = getReduxState((state) => state?.user?.info?.userId);

  if (!userId) {
    console.error(
      'No userId found in redux state classicPhotoVideoCompletionEvent',
    );
  }

  console.log('classicPhotoVideoCompletionEvent');

  if (groupId && challengeId) {
    const completeTime = Date.now();

    let path = `scavengerhunt/completions/${groupId}/location_v2/allChallenges/${challengeId}/completions/${userId}`;
    let challengePointsPath = `scavengerhunt/completions/${groupId}/location_v2/allChallenges/${challengeId}/points`;
    let photoUrlPath = `scavengerhunt/completions/${groupId}/location_v2/allChallenges/${challengeId}/photoUrl`;
    let photoThumbPath = `scavengerhunt/completions/${groupId}/location_v2/allChallenges/${challengeId}/thumbnail`;
    let photoSharePath = `scavengerhunt/completions/${groupId}/location_v2/allChallenges/${challengeId}/shareUUID`;
    let completeTimePath = `scavengerhunt/completions/${groupId}/location_v2/allChallenges/${challengeId}/completeTime`;
    if (isPlayerChallenge) {
      path = `scavengerhunt/completions/${groupId}/player/${challengeId}/completions/${userId}`;
      challengePointsPath = `scavengerhunt/completions/${groupId}/player/${challengeId}/points`;
      photoUrlPath = `scavengerhunt/completions/${groupId}/player/${challengeId}/photoUrl`;
      photoThumbPath = `scavengerhunt/completions/${groupId}/player/${challengeId}/thumbnail`;
      photoSharePath = `scavengerhunt/completions/${groupId}/player/${challengeId}/shareUUID`;
      completeTimePath = `scavengerhunt/completions/${groupId}/player/${challengeId}/completeTime`;
    }

    const params = {
      userId,
      answeredCorrectly: true,
      answeredAt: completeTime,
      photoUrl,
      thumbnail,
    };

    updateDataAtPath(path, params);

    if (overridePointsEarned != null && overridePointsEarned >= 0) {
      updateDataAtPath(challengePointsPath, overridePointsEarned);
    }

    updateDataAtPath(photoUrlPath, photoUrl);
    updateDataAtPath(photoThumbPath, thumbnail);
    updateDataAtPath(photoSharePath, shareUUID);
    // ensure we wait for completeTime to be written
    await updateDataAtPath(completeTimePath, completeTime);

    const updatedIds = {
      groupId,
      eventId: eventId || null,
    };

    const email = getReduxState((state) => state?.user?.info?.email);
    const teamName = getReduxState((state) => state?.group?.info?.teamName);
    const is_test = email?.includes?.('@lets') || teamName?.includes?.('test');

    const photoPath = `photos/${groupId}/photos/${shareUUID}`;
    const photoCompletionObject: PhotoCompletionObject = {
      shareUUID: shareUUID,
      groupId,
      eventId: eventId || null,
      userId: userId || 'error',
      email,
      teamName,
      completionId: uuidv4(),
      challenge: description,
      type: 'Location Photo Challenge',
      date: Date.now(),
      is_test: is_test || false,
      dateFormated: moment().format('YYYY-MM-DDTHH:mm:ss.sssZ'),
      downloadURL: photoUrl,
      photoUrl,
      thumbnail,
      path: photoPath,
    };
    console.log({photoCompletionObject});

    updateDataAtPath(`photos/${groupId}`, updatedIds);

    updateDataAtPath(photoPath, photoCompletionObject);
  }
};

export const setHuntTimerStart = async (): Promise<void> => {
  logForCrashlytics('setHuntTimerStart fired in huntFunctions_v2');

  const groupId = getReduxState((state) => state?.group?.info?.groupId);

  if (groupId) {
    const path = `scavengerhunt/completions/${groupId}/location_v2/timerStart`;

    // double check that the timer is not already started
    const existingTimer = await getDataAtPath(path);
    if (existingTimer) {
      return console.log('timer already started', existingTimer);
    }

    const startTime = Date.now();
    console.log({path, startTime});

    updateDataAtPath(path, startTime);
  }
};

export const setHuntTimerFinish = (): void => {
  const groupId = getReduxState((state) => state?.group?.info?.groupId);
  logForCrashlytics('setHuntTimerStart fired in huntFunctions_v2');

  if (groupId) {
    const path = `scavengerhunt/completions/${groupId}/location_v2/timerFinish`;
    const finishTime = Date.now();
    console.log({path, finishTime});

    updateDataAtPath(path, finishTime);
  }
};

export const markClassicLocationAllComplete = (locationId: string): void => {
  const groupId = getReduxState((state) => state?.group?.info?.groupId);

  if (groupId) {
    activatePlayerChallenge(groupId);
  } else {
    console.error('markClassicLocationAllComplete no groupId');
  }

  if (groupId && locationId) {
    const path = `scavengerhunt/completions/${groupId}/location_v2/allChallenges/${locationId}/completeTime`;
    const completeTime = Date.now();

    console.log('mark all chals complete');

    updateDataAtPath(path, completeTime);
  }
};

export const activatePlayerChallenge = (groupId: string) => {
  const playerChallenges = getReduxState(
    (state) => state?.playerChallenges?.playerChallengeData,
  );

  const formattedPlayerChallenges = Object.values(playerChallenges || {});

  const userId = getReduxState((state) => state.user?.userId);
  const players = getReduxState((state) => state.group?.info?.players);

  if (!players) {
    console.log('No players found for this group.');
    return;
  }

  const allPlayers = Object.values(players);

  // Filter players whose parentUserId matches the current userId
  const devicePlayers = allPlayers.filter(
    (player) => userId === player.parentUserId,
  );
  const devicePlayerIds = devicePlayers.map((player) => player.userId);

  console.log('activatePlayerChallenge device player ids', devicePlayerIds);

  // Loop through each devicePlayerId and attempt to find a challenge to update
  devicePlayerIds.forEach((devicePlayerId) => {
    // Find the first challenge that is not shown AND matches the devicePlayerId
    const challengeToUpdate = formattedPlayerChallenges.find(
      (challenge: any) =>
        challenge.show === false && challenge.userId === devicePlayerId,
    );

    if (challengeToUpdate) {
      // Update the challenge's `show` value to true in local state
      challengeToUpdate.show = true;

      const path = `scavengerhunt/completions/${groupId}/player/${challengeToUpdate.challengeId}`;

      try {
        updateDataAtPath(path, {show: true});
        console.log(
          `Updated challenge ${challengeToUpdate.challengeId} for player ${devicePlayerId} to show = true`,
        );
      } catch (error) {
        console.error(`Failed to update challenge in Firebase: ${error}`);
      }
    } else {
      console.log(
        `No player challenges found with show = false for player ${devicePlayerId}`,
      );
    }
  });
};

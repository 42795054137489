import useTypedSelector, {deepEqualityCheck} from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  Alert,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';

////////////// utils
import {classicPhotoVideoCompletionEvent} from 'src/Utils/huntFunctions_classic';
import {addExtraHuntTime} from 'src/Utils/huntFunctions';

import {apiCheckAnyChallenge} from 'src/Utils/apiCalls';

/////////////// components
import {navigationRef} from 'src/Nav/navigationHelpers';
import {
  BodyText,
  ClassicChallengeCard,
  ContinueButton,
} from 'src/Modules/CommonView';
// import {useUserId} from 'src/Hooks/reduxHooks';
import CustomActivityIndicator from 'src/Modules/CommonView/CustomActivityIndicator';
import {finishHunt, saveGroupPosition} from 'src/Utils/huntFunctions';
// import {useLocation} from 'src/Hooks/gameHooks';

import {TChallenge} from 'src/types/TChallenge';
import {cloudFlareOptimizeImage, getReduxState} from 'src/Utils/helpers';
import ExpoImage from 'src/Utils/ExpoImage';
import {FlatList} from 'react-native-gesture-handler';
import {usePercentageComplete} from 'src/Hooks/gameHooks';
import {calculateDistance} from 'src/Utils/distanceHelpers';
import {updateDataAtPath} from 'src/Utils/fireUtils';
import {setChallengeStartTime} from 'src/Utils/huntFunctions_v2';

function throttle<T extends (...args: any[]) => any>(
  func: T,
  delay: number,
): (...args: Parameters<T>) => ReturnType<T> | void {
  let lastCall = 0;
  return function (...args: Parameters<T>): ReturnType<T> | void {
    const now = new Date().getTime();
    if (now - lastCall < delay) {
      return;
    }
    lastCall = now;
    return func(...args);
  };
}

const basePhotoUrl = 'photos.letsroam.com';

interface ChallengesListProps {
  almostEndOfHunt: boolean;
  availableChallengeIds: string[];
  completedChallengeIds: string[];
  challengeHeader?: string;
  challengeHeaderStyle?: TextStyle;
  challengeHeaderBody?: string;
  challengeHeaderBodyStyle?: ViewStyle | TextStyle;
  containerStyle?: ViewStyle;
}

const ChallengesList: React.FC<ChallengesListProps> = ({
  almostEndOfHunt,
  availableChallengeIds,
  completedChallengeIds,
  challengeHeaderBodyStyle = {},
  containerStyle = {},
}) => {
  const huntType = useTypedSelector((state) => state.group?.info?.huntType);
  const isHybridHunt = [
    'murder_mystery',
    'virtual_coworker_feud',
    'coworker_feud',
    'virtual_office_games',
    'escape_room',
  ].includes(huntType || '');

  const eventId = useTypedSelector((state) => state?.group?.info?.eventId);

  const playerChallenges = useTypedSelector(
    (state) => state?.playerChallenges?.playerChallengeData,
  );

  console.log(
    'current playerChallenges',
    Object.values(playerChallenges || {}),
  );

  let unlockedPlayerChallenges: TChallenge[] = [];
  let lockedPlayerChallenges: TChallenge[] = [];

  if (playerChallenges) {
    const challenges = Object.values(playerChallenges);

    unlockedPlayerChallenges = challenges.filter(
      (playerChallenge) =>
        playerChallenge.show &&
        !playerChallenge.completeTime &&
        !playerChallenge.photoUrl,
    );

    lockedPlayerChallenges = challenges.filter(
      (playerChallenge) =>
        !playerChallenge.show &&
        !playerChallenge.completeTime &&
        !playerChallenge.photoUrl,
    );
  }

  let unlockedPlayerChallengeIds = unlockedPlayerChallenges.map(
    (challenge) => challenge.challengeId,
  );

  let lockedPlayerChallengeIds = lockedPlayerChallenges.map(
    (challenge) => challenge.challengeId,
  );

  const timerLimitMinutes = useTypedSelector(
    (state) => state.game_v2?.timerLimitMinutes,
  );
  const timerStart = useTypedSelector((state) => state.game_v2?.timerStart);

  // Calculate the remaining minutes using the start time and total limit
  const calculateMinutesRemaining = useCallback(() => {
    const start = timerStart ?? 0;
    const limit = timerLimitMinutes ?? 0;

    if (start === 0 || limit <= 0) {
      return limit;
    }

    const elapsedMinutes = (Date.now() - start) / (60 * 1000); // Convert milliseconds to minutes
    return limit - elapsedMinutes;
  }, [timerStart, timerLimitMinutes]);

  const [minutesLeft, setMinutesLeft] = useState(
    Math.ceil(calculateMinutesRemaining()),
  );
  const [useUnsorted, setUseUnsorted] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      const remainingMinutes = calculateMinutesRemaining();
      setMinutesLeft(Math.ceil(remainingMinutes));
    }, 1000); // Update every second

    return () => clearInterval(interval);
  }, [calculateMinutesRemaining]);

  const group = useTypedSelector((state) => state.group?.info);

  const userLat =
    useTypedSelector((state) => state.location?.region?.latitude) || 0;
  const userLong =
    useTypedSelector((state) => state.location?.region?.longitude) || 0;

  const allChallenges = useTypedSelector(
    (state) => state.game_v2.allChallenges,
  );

  const calculateDistances = () => {
    // Define distances as an object with string keys and number values
    const distances: Record<string, number> = {};

    availableChallengeIds.forEach((challengeId) => {
      const challenge = allChallenges?.[challengeId];

      if (challenge?.lat && challenge?.long) {
        distances[challengeId] = calculateDistance(
          challenge.lat,
          challenge.long,
          Number(userLat),
          Number(userLong),
          'FEET',
        );
      } else {
        distances[challengeId] = Infinity; // Assign a high value if location is missing
      }
    });

    return distances;
  };

  const [distanceData, setDistanceData] = useState(calculateDistances());

  const updateDistances = useCallback(
    throttle(() => {
      setDistanceData(calculateDistances());
    }, 10000), // throttle interval (10s)
    [userLat, userLong, availableChallengeIds],
  );

  useEffect(() => {
    updateDistances();
  }, [
    userLat,
    userLong,
    availableChallengeIds,
    allChallenges,
    updateDistances,
  ]);

  // Sort the challenges based on distance
  const sortedChallenges = useMemo(() => {
    return [...availableChallengeIds].sort((a, b) => {
      return (distanceData[a] || Infinity) - (distanceData[b] || Infinity);
    });
  }, [availableChallengeIds, distanceData]);

  if (!availableChallengeIds || !completedChallengeIds) {
    return (
      <View
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: constants?.color?.white,
        }}>
        <CustomActivityIndicator />
      </View>
    );
  }

  const completionPercentage = usePercentageComplete();
  const hunt_has_required_checkout_location = useTypedSelector(
    (state) => state.game_v2.hunt_has_required_checkout_location,
  );

  const challengesToDisplay = useUnsorted
    ? availableChallengeIds
    : sortedChallenges;

  return (
    <View
      style={{
        backgroundColor: constants?.color?.white1,
        marginTop: 5,
        ...containerStyle,
      }}>
      <View style={{marginVertical: 5}}>
        {completionPercentage > 95 && !hunt_has_required_checkout_location && (
          <View style={{paddingBottom: 50}}>
            <BodyText
              textStyle={{
                marginBottom: 10,
                marginTop: 10,
                ...challengeHeaderBodyStyle,
              }}
              text={`When you are ready click the finish hunt button and see your final score.`}
            />
            <ContinueButton
              text={'Finish Hunt!'}
              handlePress={() => {
                console.log('done???');
                console.log('finish hunt');

                console.log('finishHunt');
                finishHunt();
              }}
              containerStyle={{
                marginTop: 30,
              }}
            />
          </View>
        )}

        {completionPercentage > 95 && !!hunt_has_required_checkout_location && (
          <View style={{paddingBottom: 50}}>
            <BodyText
              textStyle={{
                marginBottom: 10,
                marginTop: 10,
                ...challengeHeaderBodyStyle,
              }}
              text={`You are almost done with the hunt. Go to the checkout location.`}
            />
          </View>
        )}

        {!eventId &&
          minutesLeft <= 30 &&
          (group?.huntTimeExtendedCount === undefined ||
            group.huntTimeExtendedCount === null ||
            group.huntTimeExtendedCount < 1) && (
            <View>
              <BodyText
                text={`⏱️ You Have ${minutesLeft} Minute${
                  minutesLeft === 1 ? '' : 's'
                } Remaining`}
                textStyle={{
                  textAlign: 'center',
                  marginHorizontal: 10,
                  lineHeight: 20,
                  marginVertical: 5,
                  fontFamily: constants?.font.circularBold,
                  ...challengeHeaderBodyStyle,
                }}
              />

              <ContinueButton
                text={'Add 30 Minutes for 1500 Pts'}
                handlePress={async () => {
                  await addExtraHuntTime();
                  Alert.alert('Successfully added 30 minutes!');
                }}
                containerStyle={{
                  marginTop: 0,
                }}
              />
            </View>
          )}

        {completionPercentage < 95 && (
          <BodyText
            text={'Complete Challenges Below & Earn Points By'}
            textStyle={{
              textAlign: 'center',
              marginHorizontal: 10,
              lineHeight: 20,
              marginVertical: 5,
              fontFamily: constants?.font.circularBold,
              ...challengeHeaderBodyStyle,
            }}
          />
        )}
        {completionPercentage < 95 && (
          <View
            style={{
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'center',
              marginTop: 5,
            }}>
            <View
              style={{
                width: '48%',
                alignItems: 'flex-start',
              }}>
              <BodyText
                text={'🏆Complete More Challenges'}
                textStyle={{
                  textAlign: 'left',
                  fontSize: 11,
                  lineHeight: 18,
                  marginTop: 3,
                  marginBottom: 5,
                  fontFamily: constants?.font.circularBold,
                }}
              />
            </View>
            <View
              style={{
                width: '48%',
                alignItems: 'flex-start',
              }}>
              <BodyText
                text={'📍Accurate Check-Ins'}
                textStyle={{
                  textAlign: 'left',
                  fontSize: 11,
                  lineHeight: 18,
                  marginTop: 3,
                  marginBottom: 5,
                  fontFamily: constants?.font.circularBold,
                }}
              />
            </View>

            <View
              style={{
                width: '48%',
                alignItems: 'flex-start',
                marginTop: 3,
              }}>
              <BodyText
                text={'⏱️ Finish Locations Quickly'}
                textStyle={{
                  textAlign: 'center',
                  fontSize: 11,
                  lineHeight: 18,
                  marginTop: 3,
                  marginBottom: 5,
                  fontFamily: constants?.font.circularBold,
                }}
              />
            </View>
            <View
              style={{
                width: '48%',
                alignItems: 'flex-start',
                marginTop: 3,
              }}>
              <BodyText
                text={'🎨 Fun, Accurate Photos'}
                textStyle={{
                  textAlign: 'left',
                  fontSize: 11,
                  lineHeight: 18,
                  marginTop: 3,
                  marginBottom: 5,
                  fontFamily: constants?.font.circularBold,
                }}
              />
            </View>
          </View>
        )}
      </View>
      {!eventId && (
        <TouchableOpacity
          onPress={() => setUseUnsorted(!useUnsorted)}
          style={{
            marginBottom: 10,
            marginTop: 3,
            marginHorizontal: 'auto',
            borderColor: constants?.color?.gray2,
            borderWidth: 1,
            borderRadius: 5,
            padding: 3,
            minWidth: 170,
          }}>
          <Text
            style={{
              fontSize: 11,
              lineHeight: 18,
              textAlign: 'center',
            }}>
            {useUnsorted
              ? '↕️ Show Closest Locations'
              : '↕️ Show Ordered Locations'}
          </Text>
        </TouchableOpacity>
      )}

      <View>
        {!!unlockedPlayerChallengeIds?.length && (
          <FlatList
            data={unlockedPlayerChallengeIds}
            renderItem={({item: challengeId, index}) => (
              <ChallengeListCard
                key={'unFinishedChallenges' + challengeId + index}
                almostEndOfHunt={almostEndOfHunt}
                challengeId={challengeId}
                index={String(index)}
                // testID={`unCompletedCard-${index}`}
                isPlayerChallenge={true}
                challengeNamePrefix={
                  isHybridHunt
                    ? huntType === 'murder_mystery'
                      ? `Mystery ${+index + 1}`
                      : `Challenge ${+index + 1}`
                    : 'Challenge'
                }
              />
            )}
          />
        )}
        {!!challengesToDisplay?.length && (
          <FlatList
            data={challengesToDisplay}
            renderItem={({item: challengeId, index}) => (
              <ChallengeListCard
                key={'unFinishedChallenges' + challengeId + index}
                almostEndOfHunt={almostEndOfHunt}
                challengeId={challengeId}
                index={String(index)}
                testID={`unCompletedCard-${index}`}
                challengeNamePrefix={
                  isHybridHunt
                    ? huntType === 'murder_mystery'
                      ? `Mystery ${+index + 1}`
                      : `Challenge ${+index + 1}`
                    : 'Challenge'
                }
              />
            )}
          />
        )}
        {!!lockedPlayerChallengeIds?.length && (
          <View style={{marginBottom: 5, marginTop: 10}}>
            <BodyText
              text="Locked Player Challenges"
              textStyle={{
                textAlign: 'center',
                marginHorizontal: 50,
                lineHeight: 20,
                marginTop: 3,
                marginBottom: 5,
                fontWeight: '700',
              }}
            />
            <BodyText
              text="After each location is completed, a new player challenge is unlocked"
              textStyle={{
                textAlign: 'center',
                marginHorizontal: 50,
                lineHeight: 20,
                marginTop: 3,
                marginBottom: 5,
                fontWeight: '700',
              }}
            />
          </View>
        )}
        {!!lockedPlayerChallengeIds?.length && (
          <FlatList
            data={lockedPlayerChallengeIds}
            renderItem={({item: challengeId, index}) => (
              <ChallengeListCard
                key={'unFinishedChallenges' + challengeId + index}
                almostEndOfHunt={almostEndOfHunt}
                challengeId={challengeId}
                index={String(index)}
                // testID={`unCompletedCard-${index}`}
                isPlayerChallenge={true}
                challengeNamePrefix={
                  isHybridHunt
                    ? huntType === 'murder_mystery'
                      ? `Mystery ${+index + 1}`
                      : `Challenge ${+index + 1}`
                    : 'Challenge'
                }
              />
            )}
          />
        )}
      </View>
      {!!completedChallengeIds?.length && (
        <View style={{marginBottom: 5, marginTop: 20}}>
          <BodyText
            text="Completed Challenges"
            textStyle={{
              textAlign: 'center',
              marginHorizontal: 50,
              lineHeight: 20,
              marginTop: 3,
              marginBottom: 5,
              fontWeight: '700',
            }}
          />
        </View>
      )}
      <View>
        {!!completedChallengeIds?.length && (
          <FlatList
            data={completedChallengeIds}
            renderItem={({item: challengeId, index}) => (
              <ChallengeListCard
                key={'finishedChallenges' + challengeId + index}
                almostEndOfHunt={almostEndOfHunt}
                challengeId={challengeId}
                index={String(index)}
                testID={`completedCard-${index}`}
                isPlayerChallenge={!!challengeId.startsWith('cp_')}
                challengeNamePrefix={
                  isHybridHunt
                    ? huntType === 'murder_mystery'
                      ? `Mystery ${+index + 1}`
                      : `Challenge ${+index + 1}`
                    : 'Challenge'
                }
              />
            )}
          />
        )}
      </View>
    </View>
  );
};

const ChallengeListCard = ({
  challengeId,
  index,
  almostEndOfHunt,
  testID,
  challengeNamePrefix = 'Challenge',
  isPlayerChallenge = false,
}: {
  challengeId: string;
  index: string;
  almostEndOfHunt: boolean;
  isPlayerChallenge?: boolean;
  testID?: string;
  challengeNamePrefix?: string;
}) => {
  const groupId = useTypedSelector((state) => state.group.info?.groupId);
  let challenge = useTypedSelector(
    (state) => state.game_v2.allChallenges?.[challengeId],
    deepEqualityCheck,
  );

  let photoGradePath = `scavengerhunt/completions/${groupId}/location_v2/allChallenges/${challengeId}`;
  if (isPlayerChallenge) {
    challenge = useTypedSelector(
      (state) => state?.playerChallenges?.playerChallengeData?.[challengeId],
    );

    photoGradePath = `scavengerhunt/completions/${groupId}/player/${challengeId}`;
  }

  const huntType = useTypedSelector((state) => state.group?.info?.huntType);
  const isHybridHunt = [
    'murder_mystery',
    'virtual_coworker_feud',
    'coworker_feud',
    'virtual_office_games',
    'escape_room',
  ].includes(huntType || '');

  const type = challenge?.type;

  const challengeList =
    type === 'location'
      ? useTypedSelector(
          (state) =>
            state.game_v2.locations?.[challenge?.locationId || '']
              ?.challengeList ?? [],
        )
      : challenge?.challengeList ?? [];

  const challengesComplete = useTypedSelector((state) => {
    const allChallenges = state.game_v2?.allChallenges;
    const challengesComplete =
      (challengeList &&
        (
          (challengeList || []).filter((key) => {
            return allChallenges?.[key]?.completeTime;
          }) || []
        ).length) ||
      0;
    return challengesComplete;
  });

  const totalLocationPoints = useTypedSelector((state) => {
    const allChallenges = state.game_v2?.allChallenges;
    const totalLocationPoints =
      (challengeList &&
        (challengeList || []).reduce((sum, key) => {
          const challenge = allChallenges?.[key];
          return Math.round(challenge?.points || 0) + sum;
        }, challenge?.points || 0)) ||
      0;

    return totalLocationPoints;
  });

  if (!challenge) return <></>;

  const name = challenge?.name;
  const question = challenge?.question;
  const points = challenge?.points ?? 500;
  const completeTime = challenge?.completeTime;
  const groupPointsEarned = challenge?.groupPointsEarned ?? 0;
  const bonusScoreData = challenge?.bonusScoreData ?? {};

  let photoBonusPoints = 0;
  if (
    type === 'photo' &&
    bonusScoreData?.accuracy_score !== undefined &&
    bonusScoreData?.fun_of_photo_creativity !== undefined
  ) {
    photoBonusPoints =
      bonusScoreData.accuracy_score + bonusScoreData.fun_of_photo_creativity;
  }

  const photoLarge = challenge?.photoLarge;
  const required_checkout_location = challenge?.required_checkout_location;

  const photoLargeUrl = photoLarge
    ? `https://${basePhotoUrl}${photoLarge && photoLarge.substring(1)}`
    : '';

  const handleSelectChallenge = (challenge: TChallenge) => {
    const type = challenge?.type;

    // const region = location?.region || {};

    // const location = useLocation();
    const latitude = getReduxState((state) => state.location?.region?.latitude);
    const longitude = getReduxState(
      (state) => state.location?.region?.longitude,
    );

    if (latitude && longitude) {
      saveGroupPosition({latitude, longitude}, 'ChallengesList');
    }

    // for lightning player challenges
    const isLightningTimerPlayerChallenge =
      isPlayerChallenge && !!challenge?.lightningTimerSeconds;

    if (isLightningTimerPlayerChallenge && !challenge.startTime) {
      setChallengeStartTime(challengeId);
    }

    if (type === 'photo' || type === 'video') {
      return navigationRef.navigate(
        'PhotoVideoCamera',
        {
          onPhotoFirstUploadedToFirebase: (firebasePhoto) => {
            updateDataAtPath(photoGradePath, {
              preliminaryFirebasePhoto: firebasePhoto,
              preliminaryFirebasePhotoAttemptTime: Date.now(),
              isGradingInProgress: true,
            });
          },
          onCapture: (data) => {
            if (type === 'photo') {
              handlePhotoVideoComplete({
                photoUrl: data?.downloadURL,
                shareUUID: data?.shareUUID,
                challenge,
              });
            } else if (type === 'video') {
              handlePhotoVideoComplete({
                thumbnail: data?.thumbnail,
                photoUrl: data?.downloadURL,
                shareUUID: data?.shareUUID,
                challenge,
              });
            }
          },
          onScore: async (data) => {
            const checkParams = {
              challenge_id: (challenge.challengeId || '').replace('cc_', ''),
              location_id: (challenge.locationId || '').replace('l_', ''),
              photo_url: data?.downloadURL,
              shareUUID: challenge.shareUUID,
              share_UUID: data?.shareUUID,
            };
            console.log({checkParams});
            const scoringResponse = await apiCheckAnyChallenge(checkParams);
            console.log({scoringResponse});
            return scoringResponse;
          },
          onFailScore: (data) => {
            // return Alert.alert('You failed the challenge!');
            const photo_grade = data?.photo_grade || {};
            const lr_app_challenge_completion_id =
              data?.lr_app_challenge_completion_id || 0;

            const feedback_title =
              photo_grade?.feedback_title ?? 'Close but no Cigar!!!!';
            const feedback_body =
              photo_grade?.feedback_body ??
              'We were not able to detect the required elements in the image!';

            navigationRef.navigate(
              'ConfirmModal',
              {
                handleConfirm: async () => {
                  navigationRef?.goBack(
                    'ScavengerHunt_v2/Classic/ChallengesList.tsx',
                  );
                  handleSelectChallenge(challenge);
                },
                confirmText: 'Try Again',
                cancelText: 'Report Issue',
                handleCancel: () => {
                  navigationRef?.goBack(
                    'ScavengerHunt_v2/Classic/ChallengesList.tsx',
                  );
                  navigationRef.navigate(
                    'QuestionFeedBackModal',
                    {
                      challenge,
                      lr_app_challenge_completion_id: String(
                        lr_app_challenge_completion_id,
                      ),
                      type: 'daily_challenge',
                      challenged_by_user: true,
                    },
                    'ScavengerHunt_v2/Classic/ChallengesList.tsx',
                  );
                },
                thirdOption: 'Cancel',
                handleThird: () => {
                  navigationRef?.goBack(
                    'ScavengerHunt_v2/Classic/ChallengesList.tsx',
                  );
                },
                confirmColor: constants?.color?.orange,
                cancelColor: constants?.color?.blue,
                titleText: feedback_title,
                titleImage: require('src/Images/sadTrophy.png'),
                titleBodyText: feedback_body,
              },
              'ScavengerHunt_v2/Classic/ChallengesList.tsx',
            );
          },
          onReport: () => {
            navigationRef.navigate(
              'ReportModal',
              null,
              'ScavengerHunt_v2/Classic/ChallengesList.tsx',
            );
          },
          aiScoring: type == 'photo',
          text: challenge.question || 'Take a photo',
          shareUUID: challenge.shareUUID,
          photoUrl: challenge.photoUrl,
          isVideo: type == 'video',
          challengeId: challenge.challengeId,
        },
        'ScavengerHunt_v2/Classic/ChallengesList.tsx',
      );
    }

    if (!['photo', 'video', 'location']?.includes(type || '')) {
      return navigationRef.navigate(
        'ChallengeAttemptAndResultModal',
        {
          challengeId: challenge.challengeId,
          headerTitle: challengeNamePrefix,
        },
        'ScavengerHunt_v2/Classic/ChallengesList.tsx',
      );
    }

    if (type === 'location') {
      if (challenge?.completeTime && challenge?.required_checkout_location) {
        return navigationRef.navigate(
          'LocationChallengeListModal',
          {
            locationId: challenge.locationId,
          },
          'ScavengerHunt_v2/Classic/ChallengesList.tsx',
        );
      }

      return navigationRef.navigate(
        'NavigationChallengeModal',
        {
          locationId: challenge.locationId,
        },
        'ScavengerHunt_v2/Classic/ChallengesList.tsx',
      );
    }
  };

  const handlePhotoVideoComplete = ({
    photoUrl,
    shareUUID,
    challenge,
    thumbnail,
  }: {
    photoUrl: string;
    shareUUID: string;
    challenge: TChallenge;
    thumbnail?: string;
  }) => {
    const challengeId = challenge?.challengeId || '';
    const question = challenge?.question || '';

    classicPhotoVideoCompletionEvent({
      photoUrl: photoUrl,
      challengeId,
      shareUUID,
      description: question,
      thumbnail,
      isPlayerChallenge: challengeId.startsWith('cp_'),
    });
  };

  return (
    <ClassicChallengeCard
      testID={testID || `card-${index}`}
      key={`cc_item_${challengeId}${challenge.challengeId}`}
      containerStyle={
        required_checkout_location && almostEndOfHunt
          ? {borderColor: constants?.color?.red, borderWidth: 3}
          : !!isHybridHunt
          ? {
              backgroundColor: constants?.color?.white1,
              elevation: 0,
              shadowRadius: 0,
              shadowOpacity: 0,
            }
          : isPlayerChallenge
          ? !challenge.show
            ? {
                backgroundColor: '#d3d3d3',
                opacity: 0.5,
                borderWidth: 2,
                borderColor: '#A9A9A9',
              }
            : {
                borderColor: challenge?.lightningTimerSeconds
                  ? '#FFE266'
                  : type === 'emoji_decoder'
                  ? constants?.color?.teal
                  : type === 'where_map'
                  ? constants?.color?.blue
                  : type === 'photo'
                  ? constants?.color?.orange
                  : 'black',
                borderWidth: 2,
              }
          : {}
      }
      title={
        type === 'location' ? (
          `${name}`
        ) : !!completeTime ? (
          <Text>
            {challenge.name || challengeNamePrefix}
            <Text style={{fontWeight: '400'}}>{` - Complete`}</Text>
          </Text>
        ) : (
          challenge.name || challengeNamePrefix
        )
      }
      titleStyle={
        isPlayerChallenge
          ? {
              fontSize: 22,
              lineHeight: 22,
              color: challenge?.lightningTimerSeconds
                ? '#FB9B00'
                : challenge.type === 'emoji_decoder'
                ? constants?.color?.teal
                : challenge.type === 'where_map'
                ? constants?.color?.blue
                : challenge.type === 'photo'
                ? constants?.color?.orange
                : 'black',
            }
          : {}
      }
      lat={type === 'location' ? challenge.lat ?? undefined : undefined}
      long={type === 'location' ? challenge.long ?? undefined : undefined}
      text={
        isPlayerChallenge && challenge?.firstName ? (
          `for ${challenge?.firstName}`
        ) : type === 'location' ? (
          required_checkout_location ? (
            <Text>
              <Text style={{color: constants?.color?.red}}>
                Check in at the ending location!{' '}
              </Text>{' '}
              You need to check in here within the last{' '}
              {constants?.checkoutTimeLimit} minutes of the hunt or after you
              finish 80% of challenges.
            </Text>
          ) : (
            `Complete ${
              huntType === 'barHunt'
                ? ''
                : challengeList && challengeList?.length + ' '
            }challenges here`
          )
        ) : type === 'emoji_decoder' ? (
          challenge?.challengePhoto ? (
            <ExpoImage
              style={{height: 50, width: 50}}
              resizeMode="cover"
              source={{
                uri: cloudFlareOptimizeImage(challenge?.challengePhoto) || '',
              }}
            />
          ) : (
            <BodyText
              textStyle={{
                textAlign: 'center',
                fontWeight: '400',
                marginHorizontal: 60,
                fontSize: 15,
                lineHeight: 16,
                fontFamily: 'Jakarta',
                fontStyle: 'italic',
              }}
              text={
                question && question.match(/\\u[\dA-Fa-f]{4}/)
                  ? JSON.parse(question)
                  : question || ''
              }
            />
          )
        ) : question && question.match(/\\u[\dA-Fa-f]{4}/) ? (
          JSON.parse(question)
        ) : (
          question || ''
        )
      }
      secondaryText={`${totalLocationPoints} points possible`}
      icon={
        !!isHybridHunt && !!completeTime
          ? 'check'
          : !!isHybridHunt && !completeTime
          ? 'question'
          : type === 'photo'
          ? 'camera'
          : type === 'video'
          ? 'video-camera'
          : type === 'emoji_decoder'
          ? 'smile-o'
          : type === 'location'
          ? 'map-marker'
          : type === 'true_false'
          ? 'university'
          : type === 'multiple_choice'
          ? 'university'
          : type === 'fill_in_the_blank' ||
            type == 'fill_in_the_blank_traditional'
          ? 'university'
          : 'university'
      }
      iconImage={
        isPlayerChallenge
          ? !challenge.show
            ? require('src/Images/Icons/challenge-lock.png')
            : challenge.lightningTimerSeconds
            ? require('src/Images/Icons/lightning.png')
            : type === 'emoji_decoder'
            ? require('src/Images/Icons/emoji-decoder.png')
            : type === 'photo'
            ? require('src/Images/Icons/photo-role.png')
            : type === 'where_map'
            ? require('src/Images/Icons/guess-where.png')
            : require('src/Images/Icons/emoji-decoder.png')
          : undefined
      }
      backgroundColor={
        isPlayerChallenge && challenge.lightningTimerSeconds
          ? '#FFE266' // Timer Limit background color
          : type === 'photo' || (!!isHybridHunt && !!completeTime)
          ? constants?.color?.orange
          : !!isHybridHunt && !completeTime
          ? constants?.color?.teal
          : type === 'video'
          ? constants?.color?.blue
          : type === 'where_map'
          ? constants?.color?.blue
          : type === 'location'
          ? constants?.color?.black_30
          : constants?.color?.teal
      }
      backgroundUrl={photoLargeUrl}
      label={
        isPlayerChallenge && challenge.lightningTimerSeconds
          ? ''
          : type === 'location' && !!completeTime
          ? `${Math.round(totalLocationPoints || 0)} pts`
          : !!completeTime || type !== 'location'
          ? `${Math.round(
              groupPointsEarned + photoBonusPoints || points || 0,
            )} pts`
          : undefined
      }
      subLabel={groupPointsEarned && type !== 'location' ? 'earned' : ''}
      completed={
        type === 'location'
          ? challengesComplete === challengeList?.length
          : !!completeTime
      }
      completedIcon={'check'}
      completedIconColor={
        !!isHybridHunt
          ? constants?.color?.white1
          : constants?.color?.brightgreen
      }
      completedLabel={
        type === 'location'
          ? required_checkout_location
            ? undefined
            : `${challengesComplete}/${challengeList?.length}`
          : undefined
      }
      handlePress={() => {
        if (isPlayerChallenge && !challenge?.show) {
          Alert.alert(
            'Challenge Locked',
            'Complete a location to unlock this challenge!',
          );
        } else {
          handleSelectChallenge(challenge);
        }
      }}
      renderEmojiChallenge={true}
    />
  );
};

export default ChallengesList;
